import React from 'react';
import classes from './DwhPaginationRow.module.css';
import { useGetIntegrations } from '../../../hooks/useGetIntegrations';
import { useAppSelector } from '../../../hooks/hooks';
import { selectorIntegrationsDwhPage } from '../../../store/dwhPageSlice';
import Pagination from '../../../shared-front/Pagination/Pagination';

const DwhPaginationRow: React.FC = () => {
  const getIntegrations = useGetIntegrations();
  const integrations = useAppSelector(selectorIntegrationsDwhPage);

  const onChangePagination = (queryPagination: string) => {
    getIntegrations({ queryPagination });
  };

  return (
    <div className={classes.container}>
      <Pagination
        currentPageSize={integrations?.meta.per_page}
        currentPage={integrations?.meta.current_page}
        total={integrations?.meta.total}
        lastPage={integrations?.meta.last_page}
        onChangePagination={onChangePagination}
      />
    </div>
  );
};

export default DwhPaginationRow;
