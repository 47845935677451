import { useAppDispatch, useAppSelector } from './hooks';
import { getQuerySearchValue } from '../utils/getQuerySearchValue';
import { getQueryPaginationFromFirstPage } from '../utils/getQueryPaginationFromFirstPage';
import { combineQuery } from '../utils/combineQuery';
import { useCallback, useRef } from 'react';
import {
  getIntegrationsDwhThunk,
  selectorIntegrationsDwhPage,
  selectorSearchValueDwhPage,
} from '../store/dwhPageSlice';
import { abortControllerDwhPage } from '../api/dwhPageApi';

interface IUseGetIntegrations {
  searchValue?: string;
  queryPagination?: string;
}

export const useGetIntegrations = () => {
  const dispatch = useAppDispatch();
  const metaStore = useAppSelector(selectorIntegrationsDwhPage)?.meta;
  const searchValueStore = useAppSelector(selectorSearchValueDwhPage);
  const requestRef = useRef(false);

  return useCallback(
    ({ searchValue, queryPagination }: IUseGetIntegrations) => {
      const querySearchValue = getQuerySearchValue(
        searchValue ? searchValue : searchValue === '' ? '' : searchValueStore
      );

      const requestQuery = combineQuery([
        querySearchValue,
        queryPagination ? queryPagination : getQueryPaginationFromFirstPage(metaStore ? metaStore : undefined),
      ]);
      if (requestRef.current) {
        abortControllerDwhPage.abort();
        setTimeout(() => {
          requestRef.current = true;
          dispatch(getIntegrationsDwhThunk(requestQuery)).then(() => {
            requestRef.current = false;
          });
        });
      } else {
        requestRef.current = true;
        dispatch(getIntegrationsDwhThunk(requestQuery)).then(() => {
          requestRef.current = false;
        });
      }
    },
    [dispatch, metaStore, searchValueStore]
  );
};
