import { ColumnGenericType, StoreSnackBarItemType } from '../types/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { IntegrationsServerResponseType } from '../api/serverResponse';
import { serverApi } from '../api/serverApi';
import { getRejectedValue } from './rejectedValueHelper';

type DwhPageSliceType = {
  integrations: IntegrationsServerResponseType | undefined;
  searchValue: string;
  tableColumns: ColumnGenericType[];
  snackBarItems: StoreSnackBarItemType[];
  integrationsUuidWithOpenedAssets: string[];
  isLoadingIntegrations: boolean;
  error: string | undefined;
};

const initialState: DwhPageSliceType = {
  integrations: undefined,
  searchValue: '',
  tableColumns: [],
  snackBarItems: [],
  integrationsUuidWithOpenedAssets: [],
  isLoadingIntegrations: false,
  error: undefined,
};

export const getIntegrationsDwhThunk = createAsyncThunk<
  IntegrationsServerResponseType,
  string,
  { rejectValue: string }
>('app/getIntegrationsDwhThunk', async (requestQuery, { rejectWithValue }) => {
  try {
    return await serverApi.getIntegrations(requestQuery);
  } catch (e) {
    return rejectWithValue(getRejectedValue('Ошибка получения данных интеграций хранилища платформы', e));
  }
});

export const dwhPageSlice = createSlice({
  name: 'dwhPageSlice',
  initialState,
  reducers: {
    resetDwhPage: () => initialState,

    setErrorDwhPage: (state, action) => {
      state.error = action.payload;
    },
    clearErrorDwhPage: (state) => {
      state.error = undefined;
    },
    setSearchValueDwhPage: (state, action) => {
      state.searchValue = action.payload;
    },
    setTableColumnsDwhPage: (state, action) => {
      state.tableColumns = action.payload;
    },
    clearSnackBarItemsDwhPage: (state) => {
      state.snackBarItems = [];
    },
    deleteSnackBarItemDwhPage: (state, action) => {
      state.snackBarItems = state.snackBarItems.filter((item) => item.key !== action.payload.key);
    },
    pushSnackBarItemsSuccessDwhPage: (state, action) => {
      state.snackBarItems.push({
        key: new Date().getTime(),
        message: action.payload,
        status: 'success',
        autoClose: 5,
      });
    },
    pushSnackBarItemsErrorDwhPage: (state, action) => {
      state.snackBarItems.push({
        key: new Date().getTime(),
        message: action.payload,
        status: 'alert',
      });
    },
    toggleDropDownIntegrationsUuidWithOpenedTasks: (state, action) => {
      if (state.integrationsUuidWithOpenedAssets?.includes(action.payload)) {
        state.integrationsUuidWithOpenedAssets = state.integrationsUuidWithOpenedAssets.filter(
          (uuid) => uuid !== action.payload
        );
      } else {
        state.integrationsUuidWithOpenedAssets = state.integrationsUuidWithOpenedAssets
          ? [...state.integrationsUuidWithOpenedAssets, action.payload]
          : [action.payload];
      }
    },
    updateIntegrationInStore: (state, action) => {
      if (state.integrations?.data) {
        state.integrations.data = state.integrations?.data.map((integration) =>
          integration._uuid === action.payload._uuid ? action.payload : integration
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIntegrationsDwhThunk.fulfilled, (state, action) => {
        state.isLoadingIntegrations = false;
        state.integrations = action.payload;
      })
      .addCase(getIntegrationsDwhThunk.pending, (state) => {
        state.isLoadingIntegrations = true;
        state.integrations = undefined;
        state.error = undefined;
      })
      .addCase(getIntegrationsDwhThunk.rejected, (state, action) => {
        state.isLoadingIntegrations = false;
        state.error = action.payload ? action.payload.toString() : 'Неизвестная ошибка - infoSystemsSlice';
      });
  },
});

export const {
  resetDwhPage,
  setErrorDwhPage,
  clearErrorDwhPage,
  setSearchValueDwhPage,
  setTableColumnsDwhPage,
  clearSnackBarItemsDwhPage,
  deleteSnackBarItemDwhPage,
  pushSnackBarItemsSuccessDwhPage,
  pushSnackBarItemsErrorDwhPage,
  toggleDropDownIntegrationsUuidWithOpenedTasks,
  updateIntegrationInStore,
} = dwhPageSlice.actions;

export default dwhPageSlice.reducer;

export const selectorIntegrationsDwhPage = (state: RootState) => state.dwhPage.integrations;
export const selectorSearchValueDwhPage = (state: RootState) => state.dwhPage.searchValue;
export const selectorSnackBarItemsDwhPage = (state: RootState) => state.dwhPage.snackBarItems;
export const selectorIsLoadingIntegrationsDwhPage = (state: RootState) => state.dwhPage.isLoadingIntegrations;
export const selectorTableColumnsDwhPage = (state: RootState) => state.dwhPage.tableColumns;
export const selectorIntegrationsUuidWithOpenedAssets = (state: RootState) =>
  state.dwhPage.integrationsUuidWithOpenedAssets;
export const selectorErrorDwhPage = (state: RootState) => state.dwhPage.error;
