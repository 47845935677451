import React from 'react';
import classes from './DwhDisplayTaskForm.module.css';
import { TaskIntegrationType } from '../../../../../types/types';
import { Text } from '@consta/uikit/Text';
import { IntegrationsServerResponseType } from '../../../../../api/serverResponse';
import { formatDateDDMMYYYYHHMM } from '../../../../../utils/Formatters/formatDateDDMMYYYYHHMM';

interface IDwhDisplayTaskFormProps {
  integrations: IntegrationsServerResponseType | undefined;
  task: TaskIntegrationType;
}

const DwhDisplayTaskForm: React.FC<IDwhDisplayTaskFormProps> = ({ task, integrations }) => {
  const integration = integrations?.data.find((integration) => integration._uuid === task.parent_id);
  return (
    <div className={classes.container}>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Код'}</Text>
        <Text className={classes.sectionValue}>{integration?.integration_id ? integration.integration_id : '-'}</Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Интегрируемая система'}</Text>
        <Text className={classes.sectionValue}>{integration?.system?.name ? integration.system.name : '-'}</Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Объект источник'}</Text>
        <Text className={classes.sectionValue}>{integration?.source_object ? integration.source_object : '-'}</Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Строка подключения источника'}</Text>
        <Text className={classes.sectionValue}>{task.source_conn_str ? task.source_conn_str : '-'}</Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Объект получатель'}</Text>
        <Text className={classes.sectionValue}>{task.target_object ? task.target_object : '-'}</Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Строка подключения получателя'}</Text>
        <Text className={classes.sectionValue}>{task.target_conn_str ? task.target_conn_str : '-'}</Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Дата и время последней загрузки'}</Text>
        <Text className={classes.sectionValue}>
          {task.ts_last_load ? formatDateDDMMYYYYHHMM(new Date(task.ts_last_load)) : '-'}
        </Text>
      </div>
    </div>
  );
};

export default DwhDisplayTaskForm;
