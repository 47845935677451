import React, { useEffect, useState } from 'react';
import classes from './DwhEditIntegrationForm.module.css';
import { Text } from '@consta/uikit/Text';
import RightSideBarFormSelector from '../../../../../../Components/RightSideBarFormSelector/RightSideBarFormSelector';
import Delimiter from '../../../../../../shared-front/Delimiter/Delimiter';
import { Button } from '@consta/uikit/Button';
import { InfoSystemType, IntegrationType, ItemComboboxType } from '../../../../../../types/types';
import { TextField } from '@consta/uikit/TextField';
import { UpdateIntegrationParamsType } from '../../../../../../api/dwhPageApi';
import { serverApi } from '../../../../../../api/serverApi';
import {
  pushSnackBarItemsErrorDwhPage,
  pushSnackBarItemsSuccessDwhPage,
  updateIntegrationInStore,
} from '../../../../../../store/dwhPageSlice';
import { useAppDispatch } from '../../../../../../hooks/hooks';
import { DwhRightSideBarEnum } from '../../../../DwhPage';

interface IDwhEditIntegrationFormProps {
  integration: IntegrationType;
  closeSideBar?: () => void;
  infoSystems: InfoSystemType[];
  setRightSideBarMode?: (mode: DwhRightSideBarEnum | undefined) => void;
}

const DwhEditIntegrationForm: React.FC<IDwhEditIntegrationFormProps> = ({
  integration,
  closeSideBar,
  infoSystems,
  setRightSideBarMode,
}) => {
  const dispatch = useAppDispatch();
  const [isUpdating, setIsUpdating] = useState(false);
  const [activeInfoSystem, setActiveInfoSystem] = useState<InfoSystemType | undefined>(undefined);
  const [isRequiredInfoSystem, setIsRequiredInfoSystem] = useState(false);

  useEffect(() => {
    if (!activeInfoSystem && integration.system?._uuid && infoSystems) {
      const selectedInfoSystem = infoSystems.find((infoSystem) => infoSystem._uuid === integration.system?._uuid);
      if (selectedInfoSystem) {
        setActiveInfoSystem(selectedInfoSystem);
      }
    }
  }, [activeInfoSystem, integration, infoSystems]);

  const infoSystemsItems = infoSystems?.map((system) => ({
    label: system.name ? system.name : '',
    id: system._uuid,
    _uuid: system._uuid,
  }));
  const activeInfoSystemItemIndex = infoSystemsItems?.findIndex((item) => item._uuid === activeInfoSystem?._uuid);
  const activeInfoSystemValue =
    activeInfoSystemItemIndex !== undefined && infoSystemsItems
      ? infoSystemsItems[activeInfoSystemItemIndex]
      : undefined;

  const onChangeInfoSystems = (value: ItemComboboxType | null) => {
    const selectedInfoSystem = infoSystems?.find((infoSystem) => infoSystem._uuid === value?.id);
    if (selectedInfoSystem) {
      setActiveInfoSystem(selectedInfoSystem);
      if (isRequiredInfoSystem) {
        setIsRequiredInfoSystem(false);
      }
    }
  };

  const [sourceObjectValueError, setSourceObjectValueError] = useState<string | undefined>(undefined);
  const [sourceObjectValue, setSourceObjectValue] = useState<string | null>(
    integration?.source_object ? integration?.source_object : null
  );
  const handleChangeSourceObjectValue = ({ value }: { value: string | null }) => {
    if (!!sourceObjectValue) {
      setSourceObjectValueError(undefined);
    }
    setSourceObjectValue(value ? value : '');
  };

  const [descriptionValue, setDescriptionValue] = useState<string | null>(
    integration?.description ? integration?.description : null
  );
  const handleChangeDescriptionValue = ({ value }: { value: string | null }) => setDescriptionValue(value);

  const onCancelClick = () => {
    if (closeSideBar) {
      closeSideBar();
    }
  };

  const updateIntegration = async () => {
    try {
      setIsUpdating(true);
      const params: UpdateIntegrationParamsType = {
        integrationUuid: integration._uuid,
        description: descriptionValue || '',
        uuid_system: activeInfoSystemValue?._uuid || '',
        source_object: sourceObjectValue || '',
        uuid_user_responsible: '4044925ab87dcceeef584c4cbd65a804',
        uuid_user_developer: '4044925ab87dcceeef584c4cbd65a804',
      };
      const res = await serverApi.updateIntegration(params);
      dispatch(pushSnackBarItemsSuccessDwhPage('Интеграция сохранена'));
      if (res) {
        dispatch(updateIntegrationInStore(res));
        if (setRightSideBarMode) {
          setRightSideBarMode(DwhRightSideBarEnum.DisplayIntegrationOrTask);
        }
      }
    } catch (e) {
      onCancelClick();
      dispatch(pushSnackBarItemsErrorDwhPage('Ошибка сохранения интеграции'));
    } finally {
      setIsUpdating(false);
    }
  };

  const onUpdateClick = () => {
    if (!activeInfoSystem) {
      setIsRequiredInfoSystem(true);
    }
    if (!sourceObjectValue) {
      setSourceObjectValueError('Обязательное к заполнению поле');
    }
    if (activeInfoSystem && sourceObjectValue) {
      updateIntegration().then(() => {});
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <div className={classes.section}>
            <Text className={classes.sectionTitle}>{'Код'}</Text>
            <Text className={classes.sectionValue}>
              {integration?.integration_id ? integration.integration_id : '-'}
            </Text>
          </div>
          <div className={classes.infoSystemSelectorContainer}>
            <RightSideBarFormSelector
              value={activeInfoSystemValue}
              items={infoSystemsItems}
              onChange={onChangeInfoSystems}
              required={true}
              isRequired={isRequiredInfoSystem}
              placeholder={'Выбрать'}
              label={'Интегрируемая система'}
            />
          </div>
          <div className={`${classes.sectionNext} ${classes.shortBottom}`}>
            <div className={classes.combineText}>
              <Text className={classes.sectionTitle}>{'Объект источник'}</Text>
              <Text className={classes.requiredSign}>{'*'}</Text>
            </div>
            <div className={classes.textFieldContainer}>
              <TextField
                onChange={handleChangeSourceObjectValue}
                value={sourceObjectValue}
                type="text"
                placeholder={''}
                size={'m'}
                status={sourceObjectValueError ? 'alert' : undefined}
              />
              <Text className={classes.sectionAlert}>{sourceObjectValueError}</Text>
            </div>
          </div>
          <div className={classes.section}>
            <Text className={classes.sectionTitle}>{'Получено из логов'}</Text>
            <Text className={classes.sectionValue}>{integration?.was_obtained_from_logs ? 'Да' : 'Нет'}</Text>
          </div>
          <div className={classes.padding24} />
          <div className={classes.section}>
            <Text className={classes.sectionTitle}>{'Описание'}</Text>
            <TextField
              onChange={handleChangeDescriptionValue}
              value={descriptionValue}
              type="textarea"
              rows={6}
              placeholder={''}
              size={'m'}
            />
          </div>
        </div>
      </div>
      <Delimiter />
      <div className={classes.bottomButtons}>
        <Button size={'s'} view={'ghost'} label={'Отменить'} style={{ width: '193px' }} onClick={onCancelClick} />
        <Button
          size={'s'}
          label={'Сохранить'}
          style={{ width: '193px' }}
          onClick={onUpdateClick}
          loading={isUpdating}
        />
      </div>
    </div>
  );
};

export default DwhEditIntegrationForm;
