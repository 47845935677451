import { FC } from 'react';

import classes from './StringSearchFilter.module.css';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';

interface IDescriptionFilter {
  name?: string;
  value?: string;
  onChangeValue?: (inputValue: string | null) => void;
  resetFilterFn?: () => void;
  inputType?: 'string' | 'number';
  min?: string | number;
  max?: string | number;
}

const StringSearchFilter: FC<IDescriptionFilter> = ({
  name,
  value,
  onChangeValue,
  resetFilterFn,
  inputType = 'string',
  min,
  max,
}) => {
  const handleChangeValue = (inputValue: string | null) => {
    onChangeValue && onChangeValue(inputValue);
  };

  return (
    <div className={classes.container}>
      {resetFilterFn && (
        <div className={classes.resetBtnWrapper}>
          {value && (
            <Text
              onClick={() => resetFilterFn()}
              size="s"
              view="link"
              cursor="pointer"
              className={classes.resetBtnWrapper}
            >
              Сбросить
            </Text>
          )}
        </div>
      )}
      <div className={classes.container}>
        <TextField
          name={name}
          value={value}
          onChange={({ value }) => handleChangeValue(value)}
          placeholder="Введите текст"
          type={inputType}
          className={classes.reserBtn}
          withClearButton={true}
          min={min}
          max={max}
        />
      </div>
    </div>
  );
};

export default StringSearchFilter;
