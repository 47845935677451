import React, { useState } from 'react';
import classes from './TableColumnStringSearchFilter.module.css';
import StringSearchFilter from '../../Filters/StringSearchFilter/StringSearchFilter';
import { Button } from '@consta/uikit/Button';
import { FilterParamsType } from '../../types/types';

interface ITableColumnStringSearchFilterProps {
  filterName: string;
  filterValue?: string;
  applyFilter: (filterParams: FilterParamsType) => void;
}

const TableColumnStringSearchFilter: React.FC<ITableColumnStringSearchFilterProps> = ({
  filterName,
  applyFilter,
  filterValue,
}) => {
  const [value, setValue] = useState<string>(filterValue ? filterValue : '');

  const resetFilterFn = () => {
    setValue('');
    applyFilter({ filterName, filterValue: '' });
  };

  const handleChangeValue = (inputValue: string | null) => {
    if (inputValue) {
      setValue(inputValue);
    } else {
      setValue('');
    }
  };

  const applyFilterHandler = () => {
    applyFilter({ filterName, filterValue: value });
  };

  return (
    <div className={classes.filterContainer}>
      <div className={classes.filterWrapper}>
        <StringSearchFilter value={value} resetFilterFn={resetFilterFn} onChangeValue={handleChangeValue} />
        <div className={classes.buttonsWrapper}>
          <Button label="Применить" view="primary" width="full" onClick={applyFilterHandler} />
        </div>
      </div>
    </div>
  );
};

export default TableColumnStringSearchFilter;
