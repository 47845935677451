import React from 'react';
import { IconDownload } from '@consta/uikit/IconDownload';
import CustomBlueTextButton from '../../../../../../../Components/CustomBlueTextButton/CustomBlueTextButton';

interface ILaunchHistoryButtonLogProps {
  log?: string | null;
  connectionName?: string | null;
}

const downloadLog = ({ log, connectionName }: { log: string; connectionName: string }) => {
  const element = document.createElement('a');
  const file = new Blob([log], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = `${connectionName}_history_log.txt`;
  element.click();
};

const LaunchHistoryButtonLog: React.FC<ILaunchHistoryButtonLogProps> = ({ log, connectionName }) => {
  const onClickHandler = () => {
    if (log && connectionName) {
      downloadLog({ log, connectionName });
    }
  };

  return (
    <CustomBlueTextButton
      title={'Скачать'}
      IconLeft={<IconDownload size={'xs'} style={{ color: log ? '#0078D2' : 'rgba(0,32,51,.3)', marginTop: '10px' }} />}
      onClick={onClickHandler}
      disable={!log}
    />
  );
};

export default LaunchHistoryButtonLog;
