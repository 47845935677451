import React from 'react';
import classes from './DwhTableObject.module.css';
import { IntegrationType } from '../../../../types/types';
import TableCellWithDropDown from '../../../../shared-front/Table/TableCellWithDropDown/TableCellWithDropDown';
import { useAppDispatch } from '../../../../hooks/hooks';
import { toggleDropDownIntegrationsUuidWithOpenedTasks } from '../../../../store/dwhPageSlice';

interface IDwhTableObjectsProps {
  integration: IntegrationType;
  integrationsUuidWithOpenedAssets: string[];
}

const DwhTableObject: React.FC<IDwhTableObjectsProps> = ({ integration, integrationsUuidWithOpenedAssets }) => {
  const dispatch = useAppDispatch();

  const onClickToggleDropDown = () => {
    dispatch(toggleDropDownIntegrationsUuidWithOpenedTasks(integration._uuid));
  };

  return (
    <div className={classes.container}>
      {integration.tasks?.length ? (
        <TableCellWithDropDown
          isOpenDropDown={integrationsUuidWithOpenedAssets.includes(integration._uuid)}
          onClickToggleDropDown={onClickToggleDropDown}
        />
      ) : null}
      {integration.source_object ? integration.source_object : ''}
    </div>
  );
};

export default DwhTableObject;
