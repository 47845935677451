import React, { useMemo } from 'react';
import TableWrapper from '../TableWrapper/TableWrapper';
import { Table } from '../../shared-front/Table/Table';
import { getShowColumns } from '../../shared-front/Table/utils/getShowColumns';
import HierarchyCell from '../HierarchyCell/HierarchyCell';
import { formatDateDDMMYYYY } from '../../utils/Formatters/formatDateDDMMYYYY';
import { BookmarkType } from '../../types/types';
import { useColumnsStore } from '../../hooks/useColumnsStore';
import { removeHTMLTags } from '../../utils/Formatters/removeHTMLTags';
import { useExistedOrCreateNewBookmark } from '../../hooks/useExistedOrCreateNewBookmark';
import { getTableSystemObjectsColumnsTemplate } from '../../utils/Objects/getTableSystemObjectsColumnsTemplate';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { setTableSearchStyle } from '../../utils/Formatters/setTableSearchStyle';
import { useAppDispatch } from '../../hooks/hooks';
import { FilterParamsType, SortingParamsType } from '../../shared-front/types/types';

interface IObjectsTableRdbProps {
  bookmark: BookmarkType;
  objectColumnSlug: string;
  objectDataSlug: string;
  tableHeight?: string;
  onChangeSorting?: (sortingParams: SortingParamsType[] | undefined) => void;
  onChangeFiltering?: (filteringParams: FilterParamsType) => void;
  isBookmarkColumns?: boolean;
}

const ObjectsTable: React.FC<IObjectsTableRdbProps> = ({
  bookmark,
  objectColumnSlug,
  objectDataSlug,
  tableHeight,
  onChangeSorting,
  onChangeFiltering,
  isBookmarkColumns,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [_, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const doExistedOrCreateNewBookmark = useExistedOrCreateNewBookmark();

  const columnsTemplate = useMemo(
    () =>
      getTableSystemObjectsColumnsTemplate(objectColumnSlug, bookmark, dispatch, onChangeSorting, onChangeFiltering),
    [objectColumnSlug, bookmark, dispatch, onChangeSorting, onChangeFiltering]
  );

  const columns = useColumnsStore({
    columnsStore: isBookmarkColumns ? bookmark.tableColumns : bookmark.objects?.objectsTableColumns?.[objectDataSlug],
    columnsTemplate,
  });

  const objectsData =
    bookmark.objects?.objectsData && objectDataSlug ? bookmark.objects.objectsData[objectDataSlug] : undefined;

  const tableData = objectsData?.data
    ? objectsData.data.map((item) => ({
        ...item,
        name: setTableSearchStyle(item.name) || '',
        comment: setTableSearchStyle(item.comment) || '',
        description_value: setTableSearchStyle(item.description_value) || '',
        description_name: setTableSearchStyle(item.description_name) || '',
        hierarchy: <HierarchyCell item={item} />,
        _create_ts: item._create_ts ? formatDateDDMMYYYY(new Date(item._create_ts)) : '',
        _alter_ts: item._alter_ts ? formatDateDDMMYYYY(new Date(item._alter_ts)) : '',
        ts_create: item.ts_create ? formatDateDDMMYYYY(new Date(item.ts_create)) : '',
        ts_alter: item.ts_alter ? formatDateDDMMYYYY(new Date(item.ts_alter)) : '',
        db: item.db ?? '',
        schema: item.schema ?? '',
        object: item.object ?? '',
        attr: item.attr ?? '',
      }))
    : [];

  const onRowClick = (originalRow: { [key: string]: unknown }) => {
    const item = objectsData?.data.find((item) => item._hash_key === originalRow._hash_key);
    if (item?.name) {
      const bookmark = {
        label: removeHTMLTags(item.name),
        type: objectDataSlug,
        slug: objectDataSlug === 'system' ? 'system' : item.unified_object_slug,
        hash: objectDataSlug === 'system' ? item._uuid : item._hash_key,
      } as BookmarkType;

      const searchParams = createSearchParams({
        type: `${bookmark.type}`,
        hash: `${bookmark.hash}`,
        slug: `${bookmark.slug}`,
      });
      setSearchParams(searchParams);

      doExistedOrCreateNewBookmark(bookmark);
    }
  };

  return (
    <TableWrapper columns={columns} height={tableHeight ? tableHeight : '100%'}>
      <Table
        data={tableData}
        columns={getShowColumns(columns)}
        onRowClick={onRowClick}
        isColumnsResizable={true}
        isRowHover={true}
      />
    </TableWrapper>
  );
};

export default ObjectsTable;
