import React, { useEffect, useMemo } from 'react';

import classes from './DbSchemaObjectsTab.module.css';
import { getObjectsQueryParams } from '../../utils/Objects/getObjectsQueryParams';
import { getObjectsThunk, setBookmarkTableColumns } from '../../store/catalogSlice';
import { useAppDispatch } from '../../hooks/hooks';
import ObjectTabControlRow from '../ObjectTabControlRow/ObjectTabControlRow';
import Delimiter from '../../shared-front/Delimiter/Delimiter';
import { Loader } from '@consta/uikit/Loader';
import { Text } from '@consta/uikit/Text';
import Pagination from '../../shared-front/Pagination/Pagination';
import ObjectsTable from '../ObjectsTable/ObjectsTable';
import { getTableSystemObjectsColumnsTemplate } from '../../utils/Objects/getTableSystemObjectsColumnsTemplate';
import ButtonDownload from '../ButtonDownload/ButtonDownload';
import { updateFilterParams } from '../../utils/TableFilters/updateFilterParams';
import { FilterParamsType, SortingParamsType } from '../../shared-front/types/types';
import { BookmarkType } from '../../types/types';

interface IRdbObjectsTabProps {
  bookmark: BookmarkType;
}

const DbSchemaObjectsTab: React.FC<IRdbObjectsTabProps> = ({ bookmark }) => {
  const dispatch = useAppDispatch();

  const objectsData = bookmark.objects?.objectsData;
  const searchValue = bookmark.objects?.searchValue ? bookmark.objects.searchValue : '';
  const isLoadingObjects = bookmark.objects?.isLoadingObjects;
  const hasObjects = objectsData ? objectsData[bookmark.type].data.length > 0 : false;

  const objectType = useMemo(
    () => ({
      id: `object-${bookmark.slug}-${bookmark.hash}`,
      shortName: bookmark.slug,
      label: bookmark.slug,
      slug: bookmark.type,
    }),
    [bookmark]
  );

  useEffect(() => {
    if (bookmark.hash && bookmark.slug && !bookmark.tableColumns) {
      const columnsTemplate = getTableSystemObjectsColumnsTemplate(bookmark.slug);
      const tableColumns = columnsTemplate.map((column) => ({
        title: column.title,
        isShow: column.isShow,
        accessorKey: column.accessorKey,
      }));
      dispatch(setBookmarkTableColumns({ bookmarkHash: bookmark.hash, tableColumns }));
    }
  }, [dispatch, bookmark.hash, bookmark.slug, bookmark.tableColumns]);

  useEffect(() => {
    if (!objectsData && !isLoadingObjects) {
      const queryParams = getObjectsQueryParams({
        bookmark,
        objectTypeTab: objectType,
        objectsData,
        searchValue,
      });
      dispatch(getObjectsThunk({ bookmark, queryParams, objectType }));
    }
  }, [dispatch, objectsData, bookmark, isLoadingObjects, searchValue, objectType]);

  const onChangePagination = (paginationString: string) => {
    const queryParams = getObjectsQueryParams({
      bookmark,
      objectTypeTab: objectType,
      objectsData,
      paginationString,
      searchValue,
    });
    dispatch(getObjectsThunk({ bookmark, queryParams, objectType }));
  };

  const onChangeSorting = (sortingParams: SortingParamsType[] | undefined) => {
    console.log();
    if (objectType) {
      const queryParams = getObjectsQueryParams({
        bookmark,
        objectTypeTab: objectType,
        objectsData,
        searchValue,
        sortingParams: sortingParams ? sortingParams : 'noSorting',
        paginationString: objectsData?.[bookmark.type]?.per_page
          ? `page[size]=${objectsData?.[bookmark.type]?.per_page}&page[number]=1`
          : 'page[size]=25&page[number]=1',
      });
      dispatch(getObjectsThunk({ bookmark, queryParams, objectType }));
    }
  };

  const onChangeFiltering = (filteringParams: FilterParamsType) => {
    if (objectType) {
      const newObjectsFiltering = updateFilterParams({
        currentFiltersParams: bookmark.objects?.objectsFiltering,
        filterParamsForUpdate: filteringParams,
      });

      const queryParams = getObjectsQueryParams({
        bookmark,
        objectTypeTab: objectType,
        objectsData,
        searchValue,
        filteringParams: newObjectsFiltering,
        paginationString: objectsData?.[bookmark.type]?.per_page
          ? `page[size]=${objectsData?.[bookmark.type]?.per_page}&page[number]=1`
          : 'page[size]=25&page[number]=1',
      });
      dispatch(getObjectsThunk({ bookmark, queryParams, objectType }));
    }
  };

  return (
    <div className={classes.container}>
      <ObjectTabControlRow bookmark={bookmark} objectType={objectType} className={classes.controlRow} />
      <Delimiter />
      <div className={classes.contentContainer}>
        {isLoadingObjects ? (
          <Loader />
        ) : (
          <>
            <ObjectsTable
              bookmark={bookmark}
              objectColumnSlug={bookmark.slug}
              objectDataSlug={bookmark.type}
              tableHeight={bookmark.slug === 'schema' ? 'calc(100vh - 440px)' : 'calc(100vh - 416px)'}
              onChangeSorting={onChangeSorting}
              onChangeFiltering={onChangeFiltering}
              isBookmarkColumns={true}
            />
            {!hasObjects && <Text className={classes.title}>{'Ничего не найдено'}</Text>}
          </>
        )}
      </div>
      {objectsData && (
        <Pagination
          currentPageSize={objectsData[bookmark.type].per_page}
          currentPage={objectsData[bookmark.type].current_page}
          total={objectsData[bookmark.type].total}
          lastPage={objectsData[bookmark.type].last_page}
          className={classes.pagination}
          onChangePagination={onChangePagination}
          customButton={<ButtonDownload />}
        />
      )}
    </div>
  );
};

export default DbSchemaObjectsTab;
