import { ReactNode } from 'react';
import { ColumnGenericType } from '../types/types';
import { createTableColumn } from '../shared-front/Table/utils/createTableColumn';

export type LaunchHistoryTableDataType = {
  id?: string;
  uuid?: string | null;
  connectionName?: string | null;
  typeOfConnection?: string | null;
  objects?: string | null;
  status?: ReactNode;
  airflowLink?: ReactNode;
  userName?: string | null;
  ts_start?: string | null;
  ts_end?: string | null;
  airflowConnId?: string | null;
  log?: ReactNode;
};

export const LAUNCH_HISTORY_TABLE_COLUMNS: ColumnGenericType[] = [
  // createTableColumn<LaunchHistoryTableDataType>({
  //   accessorKey: 'uuid',
  //   title: 'Идентификатор',
  //   isShow: true,
  //   size: 250,
  //   rowCellHeight: '40px',
  //   alignItemsCenter: true,
  // }),
  createTableColumn<LaunchHistoryTableDataType>({
    accessorKey: 'connectionName',
    title: 'Наименование',
    textLinesNumber: 2,
    isShow: true,
    size: 250,
  }),
  createTableColumn<LaunchHistoryTableDataType>({
    accessorKey: 'airflowConnId',
    title: 'Подключение Airflow',
    textLinesNumber: 2,
    isShow: true,
    size: 250,
  }),
  createTableColumn<LaunchHistoryTableDataType>({
    accessorKey: 'typeOfConnection',
    title: 'Тип',
    textLinesNumber: 2,
    isShow: true,
    size: 250,
  }),
  createTableColumn<LaunchHistoryTableDataType>({
    accessorKey: 'objects',
    title: 'Объекты',
    textLinesNumber: 2,
    isShow: true,
    size: 250,
  }),
  createTableColumn<LaunchHistoryTableDataType>({
    accessorKey: 'status',
    title: 'Статус',
    isShow: true,
    size: 150,
  }),
  createTableColumn<LaunchHistoryTableDataType>({
    accessorKey: 'ts_start',
    title: 'Дата и время запуска',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<LaunchHistoryTableDataType>({
    accessorKey: 'ts_end',
    title: 'Дата и время завершения',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<LaunchHistoryTableDataType>({
    accessorKey: 'userName',
    title: 'Пользователь',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<LaunchHistoryTableDataType>({
    accessorKey: 'airflowLink',
    title: 'AirFlow',
    isShow: true,
    size: 150,
  }),
  createTableColumn<LaunchHistoryTableDataType>({
    accessorKey: 'log',
    title: 'Логирование',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
];
