import { BookmarkType, CatalogSectionType, ObjectsDataType } from '../../types/types';
import { combineQuery } from '../combineQuery';
import { getPaginationQuery } from './getPaginationQuery';
import { FilterParamsType, SortingParamsType } from '../../shared-front/types/types';
import { getSortingQuery } from './getSortingQuery';

export const getObjectsQueryParams = ({
  bookmark,
  objectTypeTab,
  objectsData,
  paginationString,
  searchValue,
  resetPagination,
  sortingParams,
  filteringParams,
}: {
  bookmark: BookmarkType;
  objectTypeTab: CatalogSectionType;
  searchValue: string;
  objectsData?: ObjectsDataType;
  paginationString?: string;
  resetPagination?: boolean;
  sortingParams?: SortingParamsType[] | string;
  filteringParams?: FilterParamsType[];
}) => {
  const filterValue = bookmark.slug === 'schema' ? 'schema' : bookmark.slug === 'db' ? bookmark.type : bookmark.slug;
  const objectsQuery = `filter[${filterValue}]=${bookmark.hash}&page[type]=${objectTypeTab.slug}`;

  const paginationQuery = paginationString
    ? paginationString
    : getPaginationQuery({
        pageSize: objectsData ? objectsData[objectTypeTab.slug]?.per_page : undefined,
        pageNumber: objectsData ? (resetPagination ? 1 : objectsData[objectTypeTab.slug]?.from) : undefined,
      });
  const searchQuery = searchValue ? `filter[search]=${searchValue}` : '';

  // const sortingQuery = sortingParams
  //   ? typeof sortingParams === 'object'
  //     ? `sort=${sortingParams.sortDirection === SortingDirectionEnum.desc ? '-' : ''}${sortingParams.sortName}`
  //     : ''
  //   : bookmark.objects?.objectsSorting
  //   ? `sort=${bookmark.objects?.objectsSorting.sortDirection === SortingDirectionEnum.desc ? '-' : ''}${
  //       bookmark.objects?.objectsSorting.sortName
  //     }`
  //   : '';

  const sortingQuery = sortingParams
    ? sortingParams instanceof Array
      ? getSortingQuery(sortingParams)
      : ''
    : bookmark.objects?.objectsSorting
    ? getSortingQuery(bookmark.objects?.objectsSorting)
    : '';

  // const sortingQuery = !!sortingParams
  //   ? !!sortingParams.length
  //     ? getSortingQuery(sortingParams)
  //     : ''
  //   : getSortingQuery(bookmark.objects?.objectsSorting);

  const filteringQuery = !!filteringParams
    ? !!filteringParams.length
      ? filteringParams.map((filter) => `filter[${filter.filterName}]=${filter.filterValue}`).join('&')
      : ''
    : bookmark.objects?.objectsFiltering
    ? bookmark.objects.objectsFiltering.map((filter) => `filter[${filter.filterName}]=${filter.filterValue}`).join('&')
    : '';

  return combineQuery([
    objectsQuery,
    searchQuery,
    paginationQuery,
    filteringQuery,
    sortingQuery,
    'filter[exclude]=attributes',
    'objects=true',
  ]);
};
