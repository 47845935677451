import React from 'react';

import CustomTextField from '../CustomTextField/CustomTextField';
import { BookmarkBaseType, BookmarkType, UpdateDescriptionParamsType } from '../../types/types';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { selectorCatalogsIsUpdatingDescription, updateDescriptionThunk } from '../../store/catalogSlice';

interface ICardInputLongDescriptionProps {
  bookmark: BookmarkType;
  textFieldValue: string | undefined;
  isModeEdit: boolean | undefined;
  onIsModeEditChange: (isOpen: boolean) => void;
  onChangeTextFieldValue: (value: string) => void;
}

const CardInputLongDescription: React.FC<ICardInputLongDescriptionProps> = ({
  bookmark,
  textFieldValue,
  isModeEdit,
  onIsModeEditChange,
  onChangeTextFieldValue,
}) => {
  const dispatch = useAppDispatch();
  const isSaving = useAppSelector(selectorCatalogsIsUpdatingDescription);

  const bookmarkData = bookmark.data as BookmarkBaseType | undefined;

  const onClickUpdateDescription = (value: string) => {
    const params: UpdateDescriptionParamsType = {
      description: value === '' ? null : value,
      short_description: bookmarkData?.short_description ? bookmarkData?.short_description : null,
    };
    dispatch(updateDescriptionThunk({ bookmark, params }));
  };

  return (
    <CustomTextField
      title={'Расширенное техническое описание'}
      placeholder={'Расширенное техническое описание...'}
      value={textFieldValue}
      onSave={onClickUpdateDescription}
      isSaving={isSaving}
      isModeEdit={isModeEdit}
      onIsModeEditChange={onIsModeEditChange}
      onChangeValue={onChangeTextFieldValue}
    />
  );
};

export default CardInputLongDescription;
