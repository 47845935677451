import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { appHeaderMenuItems } from '../../appMenuAndRouting/appHeaderMenuItems';
import { appRoutes, onLogoClickRoute, PageRoutesEnum, withoutFooterRoutes } from '../../appMenuAndRouting/appRoutes';
import { appSideBarMenuItems } from '../../appMenuAndRouting/appSideBarMenuItems';
import AppFooter from '../../shared-front/AppFooter/AppFooter';
import AppHeader from '../../shared-front/AppHeader/AppHeader';
import AppRouter from '../../shared-front/AppRouter/AppRouter';
import ErrorBoundary from '../../shared-front/ErrorBoundary/ErrorBoundary';
import classes from './App.module.css';
import { presetGpnDefault, Theme } from '@consta/uikit/Theme';
import { PortalsEnum } from '../../shared-front/AppHeader/sharedSideBarMenuItems';
import { PERSIST_STORAGE_KEY } from '../../utils/constants';
import storage from 'redux-persist/lib/storage';
import { CLIENT_VERSION } from '../../index';
import ModalError from '../ModalError/ModalError';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import {
  getUserInfoThunk,
  selectorIsErrorUserInfo,
  selectorIsLoadingUserInfo,
  selectorUserInfo,
} from '../../store/appSlice';
import { Loader } from '@consta/uikit/Loader';
import { getUserMenuByPermissions } from '../../utils/User/getUserMenuByPermissions';
import { getAppRoutesByPermissions } from '../../utils/User/getAppRoutesByPermissions';
import { getAppRedirectRouteByPermissions } from '../../utils/User/getAppRedirectRouteByPermissions';
import { Location } from 'history';
import { setAssetsViewType } from '../../store/metaSlice';
import { appApi } from '../../api/appApi';
import { RouteType } from '../../shared-front/types/types';
import useGpnCounter from '../../shared-front/utils/useGpnCounter';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  useGpnCounter(950, 'REACT_APP_ENABLE_GPN', '1');

  const isLoadingUserInfo = useAppSelector(selectorIsLoadingUserInfo);
  const userInfo = useAppSelector(selectorUserInfo);
  const appSliceError = useAppSelector(selectorIsErrorUserInfo);

  const getUserInfo = useCallback(() => {
    dispatch(getUserInfoThunk());
  }, [dispatch]);

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  const onChangeLocation = useCallback((location: Location) => {
    try {
      appApi
        .postLogEvent({
          event_type: 'view',
          http_path: location.pathname ?? null,
          http_query_string: location.search ?? null,
        })
        .then();
    } catch (e: any) {
      console.log(`PostLogEvent Error: ${e.message}`);
    }
  }, []);

  const resetError = async () => {
    await storage.removeItem(`persist:${PERSIST_STORAGE_KEY}`);
    window.location.href = window.location.origin + PageRoutesEnum.MainPage;
  };

  useEffect(() => {
    dispatch(setAssetsViewType(undefined));
  }, [dispatch]);

  const [appRoutesByPermissions, setAppRoutesByPermissions] = useState<RouteType[] | undefined>(undefined);
  const [appRedirectRouteByPermissions, setAppRedirectRouteByPermissions] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (userInfo && !appRoutesByPermissions && !appRedirectRouteByPermissions) {
      setAppRoutesByPermissions(getAppRoutesByPermissions(userInfo, appRoutes));
      setAppRedirectRouteByPermissions(getAppRedirectRouteByPermissions(userInfo));
    }
  }, [userInfo, appRedirectRouteByPermissions, appRoutesByPermissions]);

  return (
    <ErrorBoundary resetError={resetError}>
      <Theme preset={presetGpnDefault}>
        <BrowserRouter>
          <div className={classes.container}>
            {isLoadingUserInfo ? (
              <div className={classes.loader}>
                <div className={classes.loaderTitle}>Авторизация пользователя</div>
                <Loader />
              </div>
            ) : (
              <>
                <AppHeader
                  portal={PortalsEnum.Meta}
                  appHeaderMenuItems={getUserMenuByPermissions(userInfo, appHeaderMenuItems)}
                  appSideBarMenuItems={{
                    ...appSideBarMenuItems,
                    children: getUserMenuByPermissions(userInfo, appSideBarMenuItems.children),
                  }}
                  onLogoClickRoute={onLogoClickRoute}
                  onLogoClick={getUserInfo}
                  authMode={
                    !!process.env.REACT_APP_IS_EXTERNAL_DOMAIN && process.env.REACT_APP_IS_EXTERNAL_DOMAIN !== 'false'
                  }
                />
                <div className={classes.pageWrapper}>
                  {appRoutesByPermissions && appRedirectRouteByPermissions && (
                    <AppRouter
                      appRoutes={getAppRoutesByPermissions(userInfo, appRoutes)}
                      appRedirectRoute={getAppRedirectRouteByPermissions(userInfo)}
                      onChangeLocation={onChangeLocation}
                    />
                  )}
                </div>
                <AppFooter
                  portalName={'Технические Метаданые'}
                  onLogoClickRoute={onLogoClickRoute}
                  onLogoClick={getUserInfo}
                  withoutFooterRoutes={withoutFooterRoutes}
                  version={CLIENT_VERSION}
                />
              </>
            )}
          </div>
          <ModalError errorMessage={appSliceError} />
        </BrowserRouter>
      </Theme>
    </ErrorBoundary>
  );
};

export default App;
