import React, { useState } from 'react';
import classes from './DwhCreateIntegrationForm.module.css';
import { Button } from '@consta/uikit/Button';
import Delimiter from '../../../../../shared-front/Delimiter/Delimiter';
import { InfoSystemType, ItemComboboxType } from '../../../../../types/types';
import RightSideBarFormSelector from '../../../../../Components/RightSideBarFormSelector/RightSideBarFormSelector';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';
import { DwhRightSideBarEnum } from '../../../DwhPage';
import { serverApi } from '../../../../../api/serverApi';
import { CreateNewIntegrationParamsType } from '../../../../../api/dwhPageApi';
import { pushSnackBarItemsErrorDwhPage, pushSnackBarItemsSuccessDwhPage } from '../../../../../store/dwhPageSlice';
import { useAppDispatch } from '../../../../../hooks/hooks';
import { useGetIntegrations } from '../../../../../hooks/useGetIntegrations';

interface IDwhCreateIntegrationFormProps {
  infoSystems: InfoSystemType[];
  closeSideBar?: () => void;
  setRightSideBarMode?: (mode: DwhRightSideBarEnum | undefined) => void;
}

const DwhCreateIntegrationForm: React.FC<IDwhCreateIntegrationFormProps> = ({
  infoSystems,
  closeSideBar,
  setRightSideBarMode,
}) => {
  const dispatch = useAppDispatch();
  const getIntegrations = useGetIntegrations();
  const [isCreating, setIsCreating] = useState(false);
  const [activeInfoSystem, setActiveInfoSystem] = useState<InfoSystemType | undefined>(undefined);
  const [isRequiredInfoSystem, setIsRequiredInfoSystem] = useState(false);

  const infoSystemsItems = infoSystems?.map((system) => ({
    label: system.name ? system.name : '',
    id: system._uuid,
    _uuid: system._uuid,
  }));
  const activeInfoSystemItemIndex = infoSystemsItems?.findIndex((item) => item._uuid === activeInfoSystem?._uuid);
  const activeInfoSystemValue =
    activeInfoSystemItemIndex !== undefined && infoSystemsItems
      ? infoSystemsItems[activeInfoSystemItemIndex]
      : undefined;

  const onChangeInfoSystems = (value: ItemComboboxType | null) => {
    const selectedInfoSystem = infoSystems?.find((infoSystem) => infoSystem._uuid === value?.id);
    if (selectedInfoSystem) {
      setActiveInfoSystem(selectedInfoSystem);
      if (isRequiredInfoSystem) {
        setIsRequiredInfoSystem(false);
      }
    }
  };

  const [sourceObjectValueError, setSourceObjectValueError] = useState<string | undefined>(undefined);
  const [sourceObjectValue, setSourceObjectValue] = useState<string | null>(null);
  const handleChangeSourceObjectValue = ({ value }: { value: string | null }) => {
    if (!!sourceObjectValue) {
      setSourceObjectValueError(undefined);
    }
    setSourceObjectValue(value ? value : '');
  };

  const [descriptionValue, setDescriptionValue] = useState<string | null>(null);
  const handleChangeDescriptionValue = ({ value }: { value: string | null }) => setDescriptionValue(value);

  const onCancelClick = () => {
    if (setRightSideBarMode) {
      setRightSideBarMode(undefined);
    }
    if (closeSideBar) {
      closeSideBar();
    }
  };

  const createIntegration = async () => {
    try {
      setIsCreating(true);
      const params: CreateNewIntegrationParamsType = {
        description: descriptionValue || '',
        uuid_system: activeInfoSystemValue?._uuid || '',
        source_object: sourceObjectValue || '',
        uuid_user_responsible: '4044925ab87dcceeef584c4cbd65a804',
        uuid_user_developer: '4044925ab87dcceeef584c4cbd65a804',
      };
      await serverApi.createNewIntegration(params);
      onCancelClick();
      dispatch(pushSnackBarItemsSuccessDwhPage('Интеграция создана'));
    } catch (e) {
      onCancelClick();
      dispatch(pushSnackBarItemsErrorDwhPage('Ошибка создания интеграции'));
    } finally {
      setIsCreating(false);
      getIntegrations({});
    }
  };

  const onCreateClick = () => {
    if (!activeInfoSystem) {
      setIsRequiredInfoSystem(true);
    }
    if (!sourceObjectValue) {
      setSourceObjectValueError('Обязательное к заполнению поле');
    }
    if (activeInfoSystem && sourceObjectValue) {
      createIntegration().then(() => {});
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <div className={classes.infoSystemSelectorContainer}>
            <RightSideBarFormSelector
              value={activeInfoSystemValue}
              items={infoSystemsItems}
              onChange={onChangeInfoSystems}
              required={true}
              isRequired={isRequiredInfoSystem}
              placeholder={'Выбрать'}
              label={'Интегрируемая система'}
            />
          </div>
          <div className={`${classes.section} ${classes.shortBottom}`}>
            <div className={classes.combineText}>
              <Text className={classes.sectionTitle}>{'Объект источник'}</Text>
              <Text className={classes.requiredSign}>{'*'}</Text>
            </div>
            <div className={classes.textFieldContainer}>
              <TextField
                onChange={handleChangeSourceObjectValue}
                value={sourceObjectValue}
                type="text"
                placeholder={''}
                size={'m'}
                status={sourceObjectValueError ? 'alert' : undefined}
              />
              <Text className={classes.sectionAlert}>{sourceObjectValueError}</Text>
            </div>
          </div>
          <div className={classes.section}>
            <Text className={classes.sectionTitle}>{'Описание'}</Text>
            <TextField
              onChange={handleChangeDescriptionValue}
              value={descriptionValue}
              type="textarea"
              rows={6}
              placeholder={''}
              size={'m'}
            />
          </div>
        </div>
      </div>
      <Delimiter />
      <div className={classes.bottomButtons}>
        <Button size={'s'} view={'ghost'} label={'Отменить'} style={{ width: '193px' }} onClick={onCancelClick} />
        <Button size={'s'} label={'Создать'} style={{ width: '193px' }} onClick={onCreateClick} loading={isCreating} />
      </div>
    </div>
  );
};

export default DwhCreateIntegrationForm;
