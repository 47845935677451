import React, { useCallback } from 'react';
import { formatDateDDMMYYYYHHMM } from '../utils/Formatters/formatDateDDMMYYYYHHMM';
import { useAppSelector } from './hooks';
import { selectorLaunchHistory } from '../store/launchHistorySlice';
import { LaunchHistoryTableDataType } from '../Templates/launchHistoryTableColumns';
import LaunchHistoryStatusBadge from '../Pages/ConnectionsPage/CPContentSelector/LaunchHistoryContent/LaunchHistoryContentContainer/LaunchHistoryTable/LaunchHistoryStatusBadge/LaunchHistoryStatusBadge';
import LaunchHistoryButtonLink from '../Pages/ConnectionsPage/CPContentSelector/LaunchHistoryContent/LaunchHistoryContentContainer/LaunchHistoryTable/LaunchHistoryButtonLink/LaunchHistoryButtonLink';
import LaunchHistoryButtonLog from '../Pages/ConnectionsPage/CPContentSelector/LaunchHistoryContent/LaunchHistoryContentContainer/LaunchHistoryTable/LaunchHistoryButtonLog/LaunchHistoryButtonLog';

export const useGetLaunchHistoryTableData = () => {
  const launchHistoryData = useAppSelector(selectorLaunchHistory)?.data;

  return useCallback(() => {
    const tableData: LaunchHistoryTableDataType[] = [];
    if (!!launchHistoryData?.length) {
      launchHistoryData.forEach((launchHistoryItem, ind) => {
        tableData.push({
          id: ind.toString(),
          // uuid: `${launchHistoryItem._uuid}`,
          connectionName: launchHistoryItem.name ? launchHistoryItem.name : '',
          airflowConnId: launchHistoryItem.airflowConnId ? launchHistoryItem.airflowConnId : '',
          typeOfConnection: launchHistoryItem.type ? launchHistoryItem.type : '',
          objects: launchHistoryItem.objects ? launchHistoryItem.objects : '',
          status: <LaunchHistoryStatusBadge status={launchHistoryItem.status} />,
          airflowLink: <LaunchHistoryButtonLink airflowLink={launchHistoryItem.airflow} />,
          userName: launchHistoryItem.user ? launchHistoryItem.user : '',
          ts_start: launchHistoryItem.ts_start ? formatDateDDMMYYYYHHMM(new Date(launchHistoryItem.ts_start)) : '',
          ts_end: launchHistoryItem.ts_end ? formatDateDDMMYYYYHHMM(new Date(launchHistoryItem.ts_end)) : '',
          log: <LaunchHistoryButtonLog log={launchHistoryItem.log} connectionName={launchHistoryItem.name} />,
        });
      });
    }
    return tableData;
  }, [launchHistoryData]);
};
