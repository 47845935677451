import { ColumnGenericType } from '../types/types';
import { createTableColumn } from '../shared-front/Table/utils/createTableColumn';
import { ReactNode } from 'react';

export type IntegrationsTableDataType = {
  integration_id?: string | null;
  systemName?: string | null;
  source_object?: ReactNode | null;
  source_conn_str?: string | null;
  target_object?: string | null;
  target_conn_str?: string | null;
  ts_last_load?: string | null;
  was_obtained_from_logs?: string | null;
  description?: string | null;
  developer?: string | null;
  responsible?: string | null;
};

export const INTEGRATIONS_TABLE_COLUMNS: ColumnGenericType[] = [
  createTableColumn<IntegrationsTableDataType>({
    accessorKey: 'integration_id',
    title: 'Код',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<IntegrationsTableDataType>({
    accessorKey: 'systemName',
    title: 'Интегрируемая система',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<IntegrationsTableDataType>({
    accessorKey: 'source_object',
    title: 'Обьект источник',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<IntegrationsTableDataType>({
    accessorKey: 'source_conn_str',
    title: 'Строка подлючения источника',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<IntegrationsTableDataType>({
    accessorKey: 'target_object',
    title: 'Обьект получатель',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<IntegrationsTableDataType>({
    accessorKey: 'target_conn_str',
    title: 'Строка подключения получателя',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<IntegrationsTableDataType>({
    accessorKey: 'ts_last_load',
    title: 'Дата и время последней загрузки',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<IntegrationsTableDataType>({
    accessorKey: 'was_obtained_from_logs',
    title: 'Получено из логов',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<IntegrationsTableDataType>({
    accessorKey: 'description',
    title: 'Описание',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<IntegrationsTableDataType>({
    accessorKey: 'developer',
    title: 'Разработчик',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<IntegrationsTableDataType>({
    accessorKey: 'responsible',
    title: 'Ответственный',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
];
