import { instanceAxios } from './instanceAxios';
import {
  CardDataPreviewServerResponseType,
  CardScriptServerResponseType,
  CatalogFilterServerDictionaryResponseType,
  GetCatalogItemsServerResponseType,
  MetaFiltersServerResponseType,
  ObjectFilterItemsServerResponseType,
  ObjectsTypesServerResponseType,
  TableOdata1cTablePartAttributesServerResponseType,
  TableViewTableFunctionAttributeServerResponseType,
} from './serverResponse';
import {
  BookmarkRdbCdbType,
  BookmarkType,
  HierarchyItemType,
  ResponseBookmarkType,
  UpdateDescriptionParamsType,
} from '../types/types';

export let abortControllerCatalogItems = new AbortController();

export const catalogApi = {
  async getCatalogItems(query: string) {
    return instanceAxios
      .get<GetCatalogItemsServerResponseType>(`/technical-metadata${query}`)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.name === 'CanceledError') {
          abortControllerCatalogItems = new AbortController();
          return { data: [], from: 0, to: 0, total: 0, per_page: 0, current_page: 0, last_page: 0 };
        } else {
          throw new Error(e.message);
        }
      });
  },

  async getServerCatalogFilters() {
    const response = await instanceAxios.get<MetaFiltersServerResponseType>(`/technical-metadata/filters`);
    return response.data;
  },

  async getCatalogFilterDictionary(filterName: string) {
    const response = await instanceAxios.get<CatalogFilterServerDictionaryResponseType>(
      `/technical-metadata/filters/${filterName}`
    );
    return response.data;
  },

  async getServerCatalogHierarchyItems(query: string) {
    const response = await instanceAxios.get<{ data: HierarchyItemType[] }>(
      `technical-metadata/hierarchy-filters${query}`
    );
    return response.data.data;
  },

  async getBookmarkData(bookmark: BookmarkType) {
    if (bookmark.type === 'system') {
      const response = await instanceAxios.get<{ data: BookmarkRdbCdbType }>(`cards/${bookmark.type}/${bookmark.hash}`);
      return response.data.data;
    } else {
      const response = await instanceAxios.get<{ data: BookmarkRdbCdbType }>(
        `cards/${bookmark.type}/${bookmark.hash}${bookmark.slug ? `/${bookmark.slug}` : ''}`
      );
      return response.data.data;
    }
  },

  async getBookmarkAttributesData({ bookmark, requestQuery }: { bookmark: BookmarkType; requestQuery: string }) {
    const response = await instanceAxios.get<TableViewTableFunctionAttributeServerResponseType>(
      `cards/${bookmark.type}/${bookmark.hash}/${bookmark.slug}/attributes${requestQuery ? requestQuery : ''}`
    );
    return response.data;
  },

  async getBookmarkPreviewData({ bookmark, requestQuery }: { bookmark: BookmarkType; requestQuery: string }) {
    const response = await instanceAxios.get<CardDataPreviewServerResponseType>(
      `cards/${bookmark.type}/${bookmark.hash}/${bookmark.slug}/preview${requestQuery ? requestQuery : ''}`
    );
    return response.data;
  },

  async getScriptData(bookmark: BookmarkType) {
    const response = await instanceAxios.get<CardScriptServerResponseType>(
      `cards/${bookmark.type}/${bookmark.hash}/${bookmark.slug}/script`
    );
    return response.data;
  },

  async updateDescription({ bookmark, params }: { bookmark: BookmarkType; params: UpdateDescriptionParamsType }) {
    const response = await instanceAxios.put<{ data: ResponseBookmarkType }>(
      `cards/${bookmark.type}/${bookmark.hash}/${bookmark.slug}`,
      params
    );
    return response.data.data;
  },

  async getObjectTypes(bookmark: BookmarkType) {
    const response = await instanceAxios.get<ObjectsTypesServerResponseType>(`${bookmark.type}s/${bookmark.hash}/meta`);
    return response.data;
  },

  async getOdata1cTablePartAttributesData(uuid: string) {
    const response = await instanceAxios.get<TableOdata1cTablePartAttributesServerResponseType>(
      `cards/odata1c/${uuid}/table_part/attributes`
    );
    return response.data;
  },

  async getObjectFilterItems({ params, filterName }: { params?: string; filterName: string }) {
    const route =
      filterName === 'unified_object'
        ? 'unified-object-types'
        : filterName === 'object_kind'
        ? 'object-kinds'
        : filterName === 'schema'
        ? 'database-schemas'
        : filterName === 'connector_type'
        ? 'connector-types'
        : filterName === 'db'
        ? 'databases'
        : filterName === 'odata-object'
        ? 'odata-objects'
        : '';
    if (!route) {
      throw new Error('Ошибка в имени Фильтра Обьектов');
    }
    const response = await instanceAxios.get<ObjectFilterItemsServerResponseType>(
      `/dictionaries/${route}${params ? params : ''}`
    );
    return response.data;
  },
};
