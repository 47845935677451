import { PageRoutesEnum } from './appRoutes';

export const appHeaderMenuItems = [
  { label: 'Главная', href: PageRoutesEnum.MainPage },
  { label: 'Каталог', href: PageRoutesEnum.CatalogPage },
  { label: 'Интегрируемые системы', href: PageRoutesEnum.InfoSystemsPage },
  { label: 'Подключения', href: PageRoutesEnum.ConnectionsPage },
  { label: 'Хранилища платформы', href: PageRoutesEnum.DwhPage },
  // { label: 'Администрирование системы', href: PageRoutesEnum.AdminPage },
];
