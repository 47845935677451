import { FilterParamsType } from '../../shared-front/types/types';

interface IUpdateFiltersParams {
  currentFiltersParams: FilterParamsType[] | undefined;
  filterParamsForUpdate: FilterParamsType;
}

export const updateFilterParams = ({ currentFiltersParams, filterParamsForUpdate }: IUpdateFiltersParams) => {
  const newFiltersParams: FilterParamsType[] = [];
  if (currentFiltersParams && !!currentFiltersParams.length) {
    if (currentFiltersParams.find((filter) => filter.filterName === filterParamsForUpdate.filterName)) {
      currentFiltersParams.forEach((curFilterParams) => {
        if (curFilterParams.filterName === filterParamsForUpdate.filterName) {
          if (filterParamsForUpdate.filterValue) {
            newFiltersParams.push(filterParamsForUpdate);
          }
        } else {
          newFiltersParams.push(curFilterParams);
        }
      });
    } else {
      currentFiltersParams.forEach((curFilterParams) => {
        newFiltersParams.push(curFilterParams);
      });
      newFiltersParams.push(filterParamsForUpdate);
    }
  } else {
    newFiltersParams.push(filterParamsForUpdate);
  }
  return newFiltersParams;
};
