import React, { useEffect } from 'react';

import classes from './SystemObjectsTab.module.css';
import ObjectTabControlRow from '../../../../../../Components/ObjectTabControlRow/ObjectTabControlRow';
import Pagination from '../../../../../../shared-front/Pagination/Pagination';
import Delimiter from '../../../../../../shared-front/Delimiter/Delimiter';
import ObjectTypeTabsRow from './ObjectTypeTabsRow/ObjectTypeTabsRow';
import { BookmarkType, CatalogSectionType } from '../../../../../../types/types';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import {
  getObjectsThunk,
  getObjectsTypesThunk,
  selectorCatalogSliceError,
  setActiveObjectTypeTab,
  setBookmarkObjectsSorting,
  setBookmarkObjectsTableColumns,
} from '../../../../../../store/catalogSlice';
import { Loader } from '@consta/uikit/Loader';
import { Text } from '@consta/uikit/Text';
import { getObjectsQueryParams } from '../../../../../../utils/Objects/getObjectsQueryParams';
import { getObjectTypeTabs } from '../../../../../../utils/Objects/getObjectTypeTabs';
import ObjectsTable from '../../../../../../Components/ObjectsTable/ObjectsTable';
import { getTableSystemObjectsColumnsTemplate } from '../../../../../../utils/Objects/getTableSystemObjectsColumnsTemplate';
import ButtonDownload from '../../../../../../Components/ButtonDownload/ButtonDownload';
import { SortingParamsType } from '../../../../../../shared-front/types/types';

interface IRdbObjectsTabProps {
  bookmark: BookmarkType;
}

const SystemObjectsTab: React.FC<IRdbObjectsTabProps> = ({ bookmark }) => {
  const dispatch = useAppDispatch();
  const catalogSliceError = useAppSelector(selectorCatalogSliceError);

  const objectsData = bookmark.objects?.objectsData;
  const searchValue = bookmark.objects?.searchValue ? bookmark.objects.searchValue : '';
  const isLoadingObjects = bookmark.objects?.isLoadingObjects;
  const activeObjectTypeTab = bookmark.objects?.activeObjectTypeTab;
  const hasActiveObjectTypeData = objectsData && objectsData[activeObjectTypeTab?.slug!]?.total > 0;
  const objectTypeTabs = getObjectTypeTabs(bookmark.objects?.objectTypes!);

  useEffect(() => {
    if (!bookmark.objects?.objectTypes && !isLoadingObjects && !catalogSliceError) {
      dispatch(getObjectsTypesThunk(bookmark));
    }
  }, [dispatch, bookmark, isLoadingObjects, catalogSliceError]);

  useEffect(() => {
    if (
      bookmark.hash &&
      activeObjectTypeTab?.slug &&
      !bookmark.objects?.objectsTableColumns?.[activeObjectTypeTab.slug]
    ) {
      const columnsTemplate = getTableSystemObjectsColumnsTemplate(activeObjectTypeTab.slug);
      const tableColumns = columnsTemplate.map((column) => ({
        title: column.title,
        isShow: column.isShow,
        accessorKey: column.accessorKey,
      }));
      dispatch(
        setBookmarkObjectsTableColumns({
          bookmarkHash: bookmark.hash,
          objectDataSlug: activeObjectTypeTab.slug,
          tableColumns: tableColumns,
        })
      );
    }
  }, [dispatch, bookmark.hash, activeObjectTypeTab?.slug, activeObjectTypeTab, bookmark.objects?.objectsTableColumns]);

  useEffect(() => {
    if (!!objectTypeTabs?.length && !activeObjectTypeTab) {
      const ind = objectTypeTabs.findIndex((section) => section.disabled === false);
      if (ind > -1) {
        dispatch(setActiveObjectTypeTab({ bookmarkHash: bookmark.hash, activeObjectTypeTab: objectTypeTabs[ind] }));
      }
    }
  }, [dispatch, bookmark.hash, activeObjectTypeTab, objectTypeTabs]);

  useEffect(() => {
    if (activeObjectTypeTab && !objectsData && !isLoadingObjects) {
      const queryParams = getObjectsQueryParams({
        bookmark,
        objectTypeTab: activeObjectTypeTab,
        objectsData,
        searchValue,
      });
      dispatch(getObjectsThunk({ bookmark, queryParams, objectType: activeObjectTypeTab }));
    }
  }, [dispatch, activeObjectTypeTab, objectsData, bookmark, isLoadingObjects, searchValue]);

  const onChangeObjectTypeTab = ({ value }: { value: CatalogSectionType }) => {
    dispatch(setActiveObjectTypeTab({ bookmarkHash: bookmark.hash, activeObjectTypeTab: value }));
    dispatch(setBookmarkObjectsSorting({ bookmarkKey: bookmark.key, sortingParams: undefined }));
    const queryParams = getObjectsQueryParams({
      bookmark,
      objectTypeTab: value,
      objectsData,
      searchValue,
    });
    dispatch(getObjectsThunk({ bookmark, queryParams, objectType: value }));
  };

  const onChangePagination = (paginationString: string) => {
    if (activeObjectTypeTab) {
      const queryParams = getObjectsQueryParams({
        bookmark,
        objectTypeTab: activeObjectTypeTab,
        objectsData,
        paginationString,
        searchValue,
      });
      dispatch(getObjectsThunk({ bookmark, queryParams, objectType: activeObjectTypeTab }));
    }
  };

  const onChangeSorting = (sortingParams: SortingParamsType[] | undefined) => {
    if (activeObjectTypeTab) {
      const queryParams = getObjectsQueryParams({
        bookmark,
        objectTypeTab: activeObjectTypeTab,
        objectsData,
        searchValue,
        sortingParams: sortingParams ? sortingParams : 'noSorting',
        paginationString: objectsData?.[bookmark.type]?.per_page
          ? `page[size]=${objectsData?.[bookmark.type]?.per_page}&page[number]=1`
          : 'page[size]=25&page[number]=1',
      });
      dispatch(getObjectsThunk({ bookmark, queryParams, objectType: activeObjectTypeTab }));
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <ObjectTabControlRow bookmark={bookmark} objectDataSlug={activeObjectTypeTab ? activeObjectTypeTab.slug : ''} />
        <ObjectTypeTabsRow
          tabs={objectTypeTabs ? objectTypeTabs : []}
          activeTab={activeObjectTypeTab}
          onChangeTab={onChangeObjectTypeTab}
          isLoadingObjects={isLoadingObjects}
        />
        <Delimiter />
      </div>
      {isLoadingObjects ? (
        <Loader />
      ) : hasActiveObjectTypeData ? (
        <ObjectsTable
          bookmark={bookmark}
          objectDataSlug={activeObjectTypeTab ? activeObjectTypeTab.slug : ''}
          objectColumnSlug={activeObjectTypeTab ? activeObjectTypeTab.slug : ''}
          tableHeight={'calc(100vh - 410px)'}
          onChangeSorting={onChangeSorting}
        />
      ) : (
        <Text className={classes.title}>{'Ничего не найдено'}</Text>
      )}
      <Pagination
        currentPageSize={objectsData?.[activeObjectTypeTab?.slug!]?.per_page}
        currentPage={objectsData?.[activeObjectTypeTab?.slug!]?.current_page}
        total={objectsData?.[activeObjectTypeTab?.slug!]?.total}
        lastPage={objectsData?.[activeObjectTypeTab?.slug!]?.last_page}
        className={classes.pagination}
        onChangePagination={onChangePagination}
        customButton={<ButtonDownload />}
      />
    </div>
  );
};

export default SystemObjectsTab;
