import { ObjectsTableRdbDataType } from '../../Templates/ObjectTableTemplates/ObjectsTableRdbColumns';
import { ObjectsTableOdata1cDataType } from '../../Templates/ObjectTableTemplates/ObjectsTableOdata1cColumns';
import { ObjectsTableDbDataType } from '../../Templates/ObjectTableTemplates/ObjectsTableDbColumns';
import { ObjectsTableSchemaDataType } from '../../Templates/ObjectTableTemplates/ObjectsTableSchemaColumns';
import { BookmarkType, ColumnGenericType, FilterDictionaryType } from '../../types/types';
import { createTableColumn } from '../../shared-front/Table/utils/createTableColumn';
import {
  updateBookmarkObjectsFiltering,
  setBookmarkObjectsSorting,
  updateBookmarkObjectsFilterDictionary,
} from '../../store/catalogSlice';
import { ObjectsTableCdbDataType } from '../../Templates/ObjectTableTemplates/ObjectsTableСdbColumns';
import TableColumnStringSearchFilter from '../../shared-front/Table/TableColumnStringSearchFilter/TableColumnStringSearchFilter';
import { getFilterValueByName } from '../TableFilters/getFilterValueByName';
import { getIsFilteredByName } from '../TableFilters/getIsFilteredByName';
import { FilterParamsType, SortingDirectionEnum, SortingParamsType } from '../../shared-front/types/types';
import { getSortingDirectionByName } from '../TableFilters/getSortingDirectionByName';
import TableColumnDateFilter from '../../shared-front/Table/TableColumnDateFilter/TableColumnDateFilter';
import { AppDispatch } from '../../store/store';
import TableColumnMultipleValuesFilter from '../../shared-front/Table/TableColumnMultipleValuesFilter/TableColumnMultipleValuesFilter';
import { getFilterDictionaryByName } from '../TableFilters/getFilterDictionaryByName';
import { serverApi } from '../../api/serverApi';

export const getTableSystemObjectsColumnsTemplate = (
  slug: string | undefined,
  bookmark?: BookmarkType,
  dispatch?: AppDispatch,
  onChangeSorting?: (sortingParams: SortingParamsType[] | undefined) => void,
  onChangeFiltering?: (filteringParams: FilterParamsType) => void
): ColumnGenericType[] => {
  const objectsSorting = bookmark?.objects?.objectsSorting;
  const objectsFiltering = bookmark?.objects?.objectsFiltering;
  const objectsFilteringDictionaries = bookmark?.objects?.objectsFilteringDictionaries;

  const toggleColumnSortingHandler = (accessorKey: string) => {
    if (dispatch && bookmark && onChangeSorting) {
      if (!objectsSorting || !objectsSorting.length) {
        const firstSortingParams = [{ sortName: accessorKey, sortDirection: SortingDirectionEnum.asc }];
        dispatch(setBookmarkObjectsSorting({ bookmarkKey: bookmark.key, sortingParams: firstSortingParams }));
        onChangeSorting(firstSortingParams);
      } else {
        const sortingByName = objectsSorting?.find((sorting) => sorting.sortName === accessorKey);
        if (!sortingByName) {
          const addedSortingParams = [
            ...objectsSorting,
            { sortName: accessorKey, sortDirection: SortingDirectionEnum.asc },
          ];
          dispatch(setBookmarkObjectsSorting({ bookmarkKey: bookmark.key, sortingParams: addedSortingParams }));
          onChangeSorting(addedSortingParams);
        } else {
          if (sortingByName.sortDirection === SortingDirectionEnum.asc) {
            const changedAscSortingParams = objectsSorting.map((sorting) =>
              sorting.sortName === sortingByName.sortName
                ? { sortName: accessorKey, sortDirection: SortingDirectionEnum.desc }
                : sorting
            );
            dispatch(setBookmarkObjectsSorting({ bookmarkKey: bookmark.key, sortingParams: changedAscSortingParams }));
            onChangeSorting(changedAscSortingParams);
          } else {
            const deletedSortingParams = objectsSorting?.filter((sorting) => sorting.sortName !== accessorKey);
            dispatch(setBookmarkObjectsSorting({ bookmarkKey: bookmark.key, sortingParams: deletedSortingParams }));
            onChangeSorting(deletedSortingParams);
          }
        }
      }
    }
  };

  const applyFilter = (filterParams: FilterParamsType, filterDictionary?: FilterDictionaryType) => {
    if (dispatch && bookmark && onChangeFiltering) {
      dispatch(updateBookmarkObjectsFiltering({ bookmarkKey: bookmark.key, filterParams: filterParams }));
      if (filterDictionary) {
        dispatch(
          updateBookmarkObjectsFilterDictionary({ bookmarkKey: bookmark.key, filterDictionary: filterDictionary })
        );
      }
      onChangeFiltering(filterParams);
    }
  };

  const OBJECTS_TABLE_CARD_IS_TAB_DB_COLUMNS: ColumnGenericType[] = [
    createTableColumn<ObjectsTableRdbDataType>({
      accessorKey: 'connector_type',
      title: 'Тип источника',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      filterComponent: () => (
        <TableColumnMultipleValuesFilter
          filterName={'connector_type'}
          filterDictionary={getFilterDictionaryByName(objectsFilteringDictionaries, 'connector_type')}
          applyFilter={applyFilter}
          getObjectFilterItems={serverApi.getObjectFilterItems}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'connector_type'),
    }),
    createTableColumn<ObjectsTableRdbDataType>({
      accessorKey: 'name',
      title: 'Наименование',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, 'name'),
      toggleColumnSorting: () => toggleColumnSortingHandler && toggleColumnSortingHandler('name'),
      filterComponent: () => (
        <TableColumnStringSearchFilter
          filterName={'name'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'name')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'name'),
    }),
    createTableColumn<ObjectsTableRdbDataType>({
      accessorKey: 'unified_object',
      title: 'Тип',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      filterComponent: () => (
        <TableColumnMultipleValuesFilter
          filterName={'unified_object'}
          filterDictionary={getFilterDictionaryByName(objectsFilteringDictionaries, 'unified_object')}
          applyFilter={applyFilter}
          getObjectFilterItems={serverApi.getObjectFilterItems}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'unified_object'),
    }),
    createTableColumn<ObjectsTableRdbDataType>({
      accessorKey: 'hierarchy',
      title: 'Иерархия',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
    }),
    createTableColumn<ObjectsTableRdbDataType>({
      accessorKey: 'description_name',
      title: 'Краткое техническое описание',
      textLinesNumber: 2,
      size: 300,
      isShow: true,
      filterComponent: () => (
        <TableColumnStringSearchFilter
          filterName={'description_name'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'description_name')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'description_name'),
    }),
    createTableColumn<ObjectsTableRdbDataType>({
      accessorKey: '_create_ts',
      title: 'Создано',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, '_create_ts'),
      toggleColumnSorting: () => toggleColumnSortingHandler('_create_ts'),
      filterComponent: () => (
        <TableColumnDateFilter
          filterName={'_create_ts'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, '_create_ts')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, '_create_ts'),
    }),
    createTableColumn<ObjectsTableRdbDataType>({
      accessorKey: '_alter_ts',
      title: 'Изменено',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, '_alter_ts'),
      toggleColumnSorting: () => toggleColumnSortingHandler('_alter_ts'),
      filterComponent: () => (
        <TableColumnDateFilter
          filterName={'_alter_ts'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, '_alter_ts')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, '_alter_ts'),
    }),
    createTableColumn<ObjectsTableRdbDataType>({
      accessorKey: 'db',
      title: 'База данных',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, 'db'),
      toggleColumnSorting: () => toggleColumnSortingHandler('db'),
      filterComponent: () => (
        <TableColumnMultipleValuesFilter
          filterName={'db'}
          filterDictionary={getFilterDictionaryByName(objectsFilteringDictionaries, 'db')}
          applyFilter={applyFilter}
          getObjectFilterItems={serverApi.getObjectFilterItems}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'db'),
    }),
    createTableColumn<ObjectsTableRdbDataType>({
      accessorKey: 'schema',
      title: 'Схема данных',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, 'schema'),
      toggleColumnSorting: () => toggleColumnSortingHandler('schema'),
      filterComponent: () => (
        <TableColumnMultipleValuesFilter
          filterName={'schema'}
          filterDictionary={getFilterDictionaryByName(objectsFilteringDictionaries, 'schema')}
          applyFilter={applyFilter}
          getObjectFilterItems={serverApi.getObjectFilterItems}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'schema'),
    }),
    createTableColumn<ObjectsTableRdbDataType>({
      accessorKey: 'object_kind',
      title: 'Тип объекта',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      filterComponent: () => (
        <TableColumnMultipleValuesFilter
          filterName={'object_kind'}
          filterDictionary={getFilterDictionaryByName(objectsFilteringDictionaries, 'object_kind')}
          applyFilter={applyFilter}
          getObjectFilterItems={serverApi.getObjectFilterItems}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'object_kind'),
    }),
    createTableColumn<ObjectsTableRdbDataType>({
      accessorKey: 'description_value',
      title: 'Расширенное техническое описание',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      filterComponent: () => (
        <TableColumnStringSearchFilter
          filterName={'description_value'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'description_value')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'description_value'),
    }),
    createTableColumn<ObjectsTableRdbDataType>({
      accessorKey: 'comment',
      title: 'Описание из системы-источник',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      filterComponent: () => (
        <TableColumnStringSearchFilter
          filterName={'comment'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'comment')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'comment'),
    }),
    createTableColumn<ObjectsTableRdbDataType>({
      accessorKey: 'ts_create',
      title: 'Создан в системе-источнике',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, 'ts_create'),
      toggleColumnSorting: () => toggleColumnSortingHandler('ts_create'),
      filterComponent: () => (
        <TableColumnDateFilter
          filterName={'ts_create'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'ts_create')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'ts_create'),
    }),
    createTableColumn<ObjectsTableRdbDataType>({
      accessorKey: 'ts_alter',
      title: 'Изменено в системе-источнике',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, 'ts_alter'),
      toggleColumnSorting: () => toggleColumnSortingHandler('ts_alter'),
      filterComponent: () => (
        <TableColumnDateFilter
          filterName={'ts_alter'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'ts_alter')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'ts_alter'),
    }),
  ];

  const OBJECTS_TABLE_CARD_IS_TAB_ODATA1C_COLUMNS: ColumnGenericType[] = [
    createTableColumn<ObjectsTableOdata1cDataType>({
      accessorKey: 'connector_type',
      title: 'Тип источника',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      filterComponent: () => (
        <TableColumnMultipleValuesFilter
          filterName={'connector_type'}
          filterDictionary={getFilterDictionaryByName(objectsFilteringDictionaries, 'connector_type')}
          applyFilter={applyFilter}
          getObjectFilterItems={serverApi.getObjectFilterItems}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'connector_type'),
    }),
    createTableColumn<ObjectsTableOdata1cDataType>({
      accessorKey: 'name',
      title: 'Наименование',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, 'name'),
      toggleColumnSorting: () => toggleColumnSortingHandler && toggleColumnSortingHandler('name'),
      filterComponent: () => (
        <TableColumnStringSearchFilter
          filterName={'name'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'name')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'name'),
    }),
    createTableColumn<ObjectsTableOdata1cDataType>({
      accessorKey: 'unified_object',
      title: 'Тип',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      filterComponent: () => (
        <TableColumnMultipleValuesFilter
          filterName={'unified_object'}
          filterDictionary={getFilterDictionaryByName(objectsFilteringDictionaries, 'unified_object')}
          applyFilter={applyFilter}
          getObjectFilterItems={serverApi.getObjectFilterItems}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'unified_object'),
    }),
    createTableColumn<ObjectsTableOdata1cDataType>({
      accessorKey: 'hierarchy',
      title: 'Иерархия',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
    }),
    createTableColumn<ObjectsTableOdata1cDataType>({
      accessorKey: 'description_name',
      title: 'Краткое техническое описание',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      filterComponent: () => (
        <TableColumnStringSearchFilter
          filterName={'description_name'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'description_name')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'description_name'),
    }),
    createTableColumn<ObjectsTableOdata1cDataType>({
      accessorKey: '_create_ts',
      title: 'Создано',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, '_create_ts'),
      toggleColumnSorting: () => toggleColumnSortingHandler && toggleColumnSortingHandler('_create_ts'),
      filterComponent: () => (
        <TableColumnDateFilter
          filterName={'_create_ts'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, '_create_ts')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, '_create_ts'),
    }),
    createTableColumn<ObjectsTableOdata1cDataType>({
      accessorKey: '_alter_ts',
      title: 'Изменено',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, '_alter_ts'),
      toggleColumnSorting: () => toggleColumnSortingHandler && toggleColumnSortingHandler('_alter_ts'),
      filterComponent: () => (
        <TableColumnDateFilter
          filterName={'_alter_ts'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, '_alter_ts')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, '_alter_ts'),
    }),
    createTableColumn<ObjectsTableOdata1cDataType>({
      accessorKey: 'object',
      title: 'Объект данных',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, 'object'),
      toggleColumnSorting: () => toggleColumnSortingHandler && toggleColumnSortingHandler('object'),
      filterComponent: () => (
        <TableColumnMultipleValuesFilter
          filterName={'odata-object'}
          filterDictionary={getFilterDictionaryByName(objectsFilteringDictionaries, 'odata-object')}
          applyFilter={applyFilter}
          getObjectFilterItems={serverApi.getObjectFilterItems}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'odata-object'),
    }),
    createTableColumn<ObjectsTableOdata1cDataType>({
      accessorKey: 'full_name',
      title: 'Полное наименование',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, 'full_name'),
      toggleColumnSorting: () => toggleColumnSortingHandler && toggleColumnSortingHandler('full_name'),
      filterComponent: () => (
        <TableColumnStringSearchFilter
          filterName={'full_name'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'full_name')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'full_name'),
    }),
    createTableColumn<ObjectsTableOdata1cDataType>({
      accessorKey: 'object_kind',
      title: 'Тип объекта',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      filterComponent: () => (
        <TableColumnMultipleValuesFilter
          filterName={'object_kind'}
          filterDictionary={getFilterDictionaryByName(objectsFilteringDictionaries, 'object_kind')}
          applyFilter={applyFilter}
          getObjectFilterItems={serverApi.getObjectFilterItems}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'object_kind'),
    }),
    createTableColumn<ObjectsTableOdata1cDataType>({
      accessorKey: 'description_value',
      title: 'Расширенное техническое описание',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      filterComponent: () => (
        <TableColumnStringSearchFilter
          filterName={'description_value'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'description_value')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'description_value'),
    }),
  ];

  const OBJECTS_TABLE_CARD_DB_COLUMNS: ColumnGenericType[] = [
    createTableColumn<ObjectsTableDbDataType>({
      accessorKey: 'name',
      title: 'Наименование',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, 'name'),
      toggleColumnSorting: () => toggleColumnSortingHandler && toggleColumnSortingHandler('name'),
      filterComponent: () => (
        <TableColumnStringSearchFilter
          filterName={'name'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'name')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'name'),
    }),
    createTableColumn<ObjectsTableDbDataType>({
      accessorKey: 'unified_object',
      title: 'Тип',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      filterComponent: () => (
        <TableColumnMultipleValuesFilter
          filterName={'unified_object'}
          filterDictionary={getFilterDictionaryByName(objectsFilteringDictionaries, 'unified_object')}
          applyFilter={applyFilter}
          getObjectFilterItems={serverApi.getObjectFilterItems}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'unified_object'),
    }),
    createTableColumn<ObjectsTableDbDataType>({
      accessorKey: 'hierarchy',
      title: 'Иерархия',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
    }),
    createTableColumn<ObjectsTableDbDataType>({
      accessorKey: 'description_name',
      title: 'Краткое техническое описание',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      filterComponent: () => (
        <TableColumnStringSearchFilter
          filterName={'description_name'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'description_name')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'description_name'),
    }),
    createTableColumn<ObjectsTableDbDataType>({
      accessorKey: '_create_ts',
      title: 'Создано',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, '_create_ts'),
      toggleColumnSorting: () => toggleColumnSortingHandler && toggleColumnSortingHandler('_create_ts'),
      filterComponent: () => (
        <TableColumnDateFilter
          filterName={'_create_ts'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, '_create_ts')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, '_create_ts'),
    }),
    createTableColumn<ObjectsTableDbDataType>({
      accessorKey: '_alter_ts',
      title: 'Изменено',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, '_alter_ts'),
      toggleColumnSorting: () => toggleColumnSortingHandler && toggleColumnSortingHandler('_alter_ts'),
      filterComponent: () => (
        <TableColumnDateFilter
          filterName={'_alter_ts'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, '_alter_ts')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, '_alter_ts'),
    }),
    createTableColumn<ObjectsTableDbDataType>({
      accessorKey: 'schema',
      title: 'Схема данных',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, 'schema'),
      toggleColumnSorting: () => toggleColumnSortingHandler && toggleColumnSortingHandler('schema'),
      filterComponent: () => (
        <TableColumnMultipleValuesFilter
          filterName={'schema'}
          filterDictionary={getFilterDictionaryByName(objectsFilteringDictionaries, 'schema')}
          applyFilter={applyFilter}
          getObjectFilterItems={serverApi.getObjectFilterItems}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'schema'),
    }),
    createTableColumn<ObjectsTableDbDataType>({
      accessorKey: 'object_kind',
      title: 'Тип объекта',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      filterComponent: () => (
        <TableColumnMultipleValuesFilter
          filterName={'object_kind'}
          filterDictionary={getFilterDictionaryByName(objectsFilteringDictionaries, 'object_kind')}
          applyFilter={applyFilter}
          getObjectFilterItems={serverApi.getObjectFilterItems}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'schema'),
    }),
    createTableColumn<ObjectsTableDbDataType>({
      accessorKey: 'description_value',
      title: 'Расширенное техническое описание',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      filterComponent: () => (
        <TableColumnStringSearchFilter
          filterName={'description_value'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'description_value')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'description_value'),
    }),
    createTableColumn<ObjectsTableDbDataType>({
      accessorKey: 'comment',
      title: 'Описание из системы-источник',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      filterComponent: () => (
        <TableColumnStringSearchFilter
          filterName={'comment'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'comment')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'comment'),
    }),
    createTableColumn<ObjectsTableRdbDataType>({
      accessorKey: 'ts_create',
      title: 'Создано в системе-источнике',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, 'ts_create'),
      toggleColumnSorting: () => toggleColumnSortingHandler && toggleColumnSortingHandler('ts_create'),
      filterComponent: () => (
        <TableColumnDateFilter
          filterName={'ts_create'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'ts_create')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'ts_create'),
    }),
    createTableColumn<ObjectsTableCdbDataType>({
      accessorKey: 'ts_alter',
      title: 'Изменено в системе-источнике',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, 'ts_alter'),
      toggleColumnSorting: () => toggleColumnSortingHandler && toggleColumnSortingHandler('ts_alter'),
      filterComponent: () => (
        <TableColumnDateFilter
          filterName={'ts_alter'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'ts_alter')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'ts_alter'),
    }),
  ];

  const OBJECTS_TABLE_CARD_SCHEMA_COLUMNS: ColumnGenericType[] = [
    createTableColumn<ObjectsTableSchemaDataType>({
      accessorKey: 'name',
      title: 'Наименование',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, 'name'),
      toggleColumnSorting: () => toggleColumnSortingHandler && toggleColumnSortingHandler('name'),
      filterComponent: () => (
        <TableColumnStringSearchFilter
          filterName={'name'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'name')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'name'),
    }),
    createTableColumn<ObjectsTableSchemaDataType>({
      accessorKey: 'unified_object',
      title: 'Тип',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      filterComponent: () => (
        <TableColumnMultipleValuesFilter
          filterName={'unified_object'}
          filterDictionary={getFilterDictionaryByName(objectsFilteringDictionaries, 'unified_object')}
          applyFilter={applyFilter}
          getObjectFilterItems={serverApi.getObjectFilterItems}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'unified_object'),
    }),
    createTableColumn<ObjectsTableSchemaDataType>({
      accessorKey: 'hierarchy',
      title: 'Иерархия',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
    }),
    createTableColumn<ObjectsTableSchemaDataType>({
      accessorKey: 'description_name',
      title: 'Краткое техническое описание',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      filterComponent: () => (
        <TableColumnStringSearchFilter
          filterName={'description_name'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'description_name')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'description_name'),
    }),
    createTableColumn<ObjectsTableSchemaDataType>({
      accessorKey: '_create_ts',
      title: 'Создано',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, '_create_ts'),
      toggleColumnSorting: () => toggleColumnSortingHandler && toggleColumnSortingHandler('_create_ts'),
      filterComponent: () => (
        <TableColumnDateFilter
          filterName={'_create_ts'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, '_create_ts')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, '_create_ts'),
    }),
    createTableColumn<ObjectsTableSchemaDataType>({
      accessorKey: '_alter_ts',
      title: 'Изменено',
      textLinesNumber: 2,
      isShow: true,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, '_alter_ts'),
      toggleColumnSorting: () => toggleColumnSortingHandler && toggleColumnSortingHandler('_alter_ts'),
      filterComponent: () => (
        <TableColumnDateFilter
          filterName={'_alter_ts'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, '_alter_ts')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, '_alter_ts'),
    }),
    createTableColumn<ObjectsTableSchemaDataType>({
      accessorKey: 'object_kind',
      title: 'Тип объекта',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      filterComponent: () => (
        <TableColumnMultipleValuesFilter
          filterName={'object_kind'}
          filterDictionary={getFilterDictionaryByName(objectsFilteringDictionaries, 'object_kind')}
          applyFilter={applyFilter}
          getObjectFilterItems={serverApi.getObjectFilterItems}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'object_kind'),
    }),
    createTableColumn<ObjectsTableSchemaDataType>({
      accessorKey: 'description_value',
      title: 'Расширенное техническое описание',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      filterComponent: () => (
        <TableColumnStringSearchFilter
          filterName={'description_value'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'description_value')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'description_value'),
    }),
    createTableColumn<ObjectsTableSchemaDataType>({
      accessorKey: 'comment',
      title: 'Описание из системы-источник',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      filterComponent: () => (
        <TableColumnStringSearchFilter
          filterName={'comment'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'comment')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'comment'),
    }),
    createTableColumn<ObjectsTableSchemaDataType>({
      accessorKey: 'ts_create',
      title: 'Создано в системе-источнике',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, 'ts_create'),
      toggleColumnSorting: () => toggleColumnSortingHandler && toggleColumnSortingHandler('ts_create'),
      filterComponent: () => (
        <TableColumnDateFilter
          filterName={'ts_create'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'ts_create')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'ts_create'),
    }),
    createTableColumn<ObjectsTableSchemaDataType>({
      accessorKey: 'ts_alter',
      title: 'Изменено в системе-источнике',
      textLinesNumber: 2,
      isShow: false,
      size: 300,
      sortingDirection: getSortingDirectionByName(objectsSorting, 'ts_alter'),
      toggleColumnSorting: () => toggleColumnSortingHandler && toggleColumnSortingHandler('ts_alter'),
      filterComponent: () => (
        <TableColumnDateFilter
          filterName={'ts_alter'}
          applyFilter={applyFilter}
          filterValue={getFilterValueByName(objectsFiltering, 'ts_alter')}
        />
      ),
      isFiltered: getIsFilteredByName(objectsFiltering, 'ts_alter'),
    }),
  ];

  return slug
    ? slug === 'rdb'
      ? OBJECTS_TABLE_CARD_IS_TAB_DB_COLUMNS
      : slug === 'odata1c'
      ? OBJECTS_TABLE_CARD_IS_TAB_ODATA1C_COLUMNS
      : slug === 'db'
      ? OBJECTS_TABLE_CARD_DB_COLUMNS
      : slug === 'schema'
      ? OBJECTS_TABLE_CARD_SCHEMA_COLUMNS
      : []
    : [];
};
