import React from 'react';

import { PageRoutesEnum } from './appRoutes';
import metaIco from '../assets/meta-ico.svg';

export const appSideBarMenuItems = {
  label: 'Технические метаданные',
  href: '/',
  icon: <img src={metaIco} alt={'meta ico'} />,
  children: [
    { label: 'Главная', href: PageRoutesEnum.MainPage },
    { label: 'Каталог', href: PageRoutesEnum.CatalogPage },
    { label: 'Интегрируемые системы', href: PageRoutesEnum.InfoSystemsPage },
    { label: 'Подключения', href: PageRoutesEnum.ConnectionsPage },
    { label: 'Хранилища платформы', href: PageRoutesEnum.DwhPage },
    // { label: 'Администрирование системы', href: PageRoutesEnum.AdminPage },
  ],
};
