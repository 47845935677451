import { SortingDirectionEnum, SortingParamsType } from '../../shared-front/types/types';

export const getSortingQuery = (objectsSorting: SortingParamsType[] | undefined) => {
  if (!objectsSorting || !objectsSorting.length) {
    return ``;
  }
  const paramsQuery: string[] = [];
  objectsSorting.forEach((sorting) => {
    if (sorting.sortDirection === SortingDirectionEnum.desc) {
      paramsQuery.push(`-${sorting.sortName}`);
    } else {
      paramsQuery.push(`${sorting.sortName}`);
    }
  });
  return !!paramsQuery.length ? `sort=${paramsQuery.join(',')}` : '';
};
