import { FilterDictionaryType } from '../../types/types';

export const getFilterDictionaryByName = (
  filterDictionaries: FilterDictionaryType[] | undefined,
  filterName: string
) => {
  if (!filterDictionaries || !filterName || filterDictionaries.length === 0) {
    return undefined;
  }
  const filterDictionary = filterDictionaries?.find((filter) => filter.filterName === filterName);
  if (!filterDictionary) {
    return undefined;
  }
  return filterDictionary;
};
