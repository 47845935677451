import React from 'react';
import classes from './DwhTableObjectString.module.css';

interface IDwhTableObjectStringProps {
  objectName?: string | null;
}

const DwhTableObjectString: React.FC<IDwhTableObjectStringProps> = ({ objectName }) => {
  if (!objectName) {
    return null;
  }
  return <div className={classes.container}>{objectName}</div>;
};

export default DwhTableObjectString;
