import React, { useEffect, useRef, useState } from 'react';
import classes from './DwhEditIntegration.module.css';
import { InfoSystemType, IntegrationType } from '../../../../../types/types';
import { serverApi } from '../../../../../api/serverApi';
import { pushSnackBarItemsErrorDwhPage } from '../../../../../store/dwhPageSlice';
import { useAppDispatch } from '../../../../../hooks/hooks';
import { Loader } from '@consta/uikit/Loader';
import DwhEditIntegrationForm from './DwhEditIntegrationForm/DwhEditIntegrationForm';
import { DwhRightSideBarEnum } from '../../../DwhPage';

interface IDwhEditIntegrationProps {
  integration: IntegrationType;
  closeSideBar?: () => void;
  setRightSideBarMode?: (mode: DwhRightSideBarEnum | undefined) => void;
}

const DwhEditIntegration: React.FC<IDwhEditIntegrationProps> = ({ integration, closeSideBar, setRightSideBarMode }) => {
  const dispatch = useAppDispatch();

  const refFirstLoad = useRef(false);
  const [isLoadingInfoSystems, setIsLoadingInfoSystems] = useState(false);
  const [infoSystems, setInfoSystems] = useState<InfoSystemType[] | undefined>(undefined);

  useEffect(() => {
    if (!infoSystems && !refFirstLoad.current) {
      setIsLoadingInfoSystems(true);
      refFirstLoad.current = true;
      serverApi
        .getInfoSystemsDictionary('?sort=name')
        .then((res) => {
          setInfoSystems(res);
        })
        .catch(() => {
          dispatch(pushSnackBarItemsErrorDwhPage('Ошибка получения списка ИС'));
        })
        .finally(() => {
          setIsLoadingInfoSystems(false);
        });
    }
  }, [dispatch, infoSystems]);

  return isLoadingInfoSystems ? (
    <div className={classes.loaderContainer}>
      <Loader />
    </div>
  ) : infoSystems ? (
    <div className={classes.container}>
      <DwhEditIntegrationForm
        integration={integration}
        infoSystems={infoSystems}
        closeSideBar={closeSideBar}
        setRightSideBarMode={setRightSideBarMode}
      />
    </div>
  ) : null;
};

export default DwhEditIntegration;
