import { useAppSelector } from './hooks';
import { selectorIntegrationsDwhPage, selectorIntegrationsUuidWithOpenedAssets } from '../store/dwhPageSlice';
import { useCallback } from 'react';
import { IntegrationsTableDataType } from '../Templates/integrationsTableColumns';
import DwhTableObject from '../Pages/DwhPage/DwhTable/DwhTableObject/DwhTableObject';
import DwhTableObjectString from '../Pages/DwhPage/DwhTable/DwhTableObject/DwhTableObjectString/DwhTableObjectString';
import { formatDateDDMMYYYYHHMM } from '../utils/Formatters/formatDateDDMMYYYYHHMM';

export const useGetIntegrationsTableData = () => {
  const integrations = useAppSelector(selectorIntegrationsDwhPage);
  const integrationsUuidWithOpenedAssets = useAppSelector(selectorIntegrationsUuidWithOpenedAssets);

  return useCallback(() => {
    const tableData: (IntegrationsTableDataType & {
      id?: string;
      rowId?: string;
    })[] = [];
    if (!!integrations?.data.length) {
      integrations.data.forEach((integration) => {
        tableData.push({
          id: `${integration._uuid}`,
          rowId: `${integration._uuid}`,
          integration_id: integration.integration_id ? integration.integration_id : '',
          systemName: integration.system?.name ? integration.system.name : '',
          source_object: (
            <DwhTableObject
              integration={integration}
              integrationsUuidWithOpenedAssets={integrationsUuidWithOpenedAssets}
            />
          ),
          was_obtained_from_logs: integration.was_obtained_from_logs ? 'Да' : 'Нет',
          description: integration.description ? integration.description : '',
          developer: integration.developer?.full_name ? integration.developer?.full_name : '',
          responsible: integration.responsible?.full_name ? integration.responsible?.full_name : '',
        });
        if (integration?.tasks?.length && integrationsUuidWithOpenedAssets.includes(integration._uuid)) {
          integration?.tasks.forEach((task) => {
            tableData.push({
              id: `${task._uuid}`,
              rowId: `${task._uuid}_innerRow`,
              source_object: <DwhTableObjectString objectName={integration.source_object} />,
              source_conn_str: task.source_conn_str ? task.source_conn_str : '',
              target_object: task.target_object ? task.target_object : '',
              target_conn_str: task.target_conn_str ? task.target_conn_str : '',
              ts_last_load: task.ts_last_load ? formatDateDDMMYYYYHHMM(new Date(task.ts_last_load)) : '',
            });
          });
        }
      });
    }
    return tableData;
  }, [integrations?.data, integrationsUuidWithOpenedAssets]);
};
