import { IntegrationsServerResponseType } from '../../api/serverResponse';
import { TaskIntegrationType } from '../../types/types';

export const getTaskByIdFromIntegrations = (
  integrations: IntegrationsServerResponseType | undefined,
  activeIntegrationOrTaskUuid: string | undefined
): TaskIntegrationType | undefined => {
  let task: TaskIntegrationType | undefined = undefined;
  if (!integrations || !integrations.data.length || !activeIntegrationOrTaskUuid) {
    return undefined;
  }
  integrations.data.forEach((integration) => {
    const taskLocal = integration.tasks?.find((task) => task._uuid === activeIntegrationOrTaskUuid);
    if (taskLocal) {
      task = { ...taskLocal };
    }
  });
  return task;
};
