import React, { useState } from 'react';
import classes from './DwhEditTask.module.css';
import { TaskIntegrationType } from '../../../../../types/types';
import { DwhRightSideBarEnum } from '../../../DwhPage';
import Delimiter from '../../../../../shared-front/Delimiter/Delimiter';
import { Button } from '@consta/uikit/Button';
import { IntegrationsServerResponseType } from '../../../../../api/serverResponse';
import { Text } from '@consta/uikit/Text';
import { formatDateDDMMYYYYHHMM } from '../../../../../utils/Formatters/formatDateDDMMYYYYHHMM';
import CustomTextFieldWithCopyBtn from '../../../../../Components/CustomTextFieldWithCopyBtn/CustomTextFieldWithCopyBtn';
import { useAppDispatch } from '../../../../../hooks/hooks';
import {
  pushSnackBarItemsErrorDwhPage,
  pushSnackBarItemsSuccessDwhPage,
  updateIntegrationInStore,
} from '../../../../../store/dwhPageSlice';
import { UpdateIntegrationTaskParamsType } from '../../../../../api/dwhPageApi';
import { serverApi } from '../../../../../api/serverApi';

interface IDwhEditTaskProps {
  task: TaskIntegrationType;
  integrations: IntegrationsServerResponseType | undefined;
  closeSideBar?: () => void;
  setRightSideBarMode?: (mode: DwhRightSideBarEnum | undefined) => void;
}

const DwhEditTask: React.FC<IDwhEditTaskProps> = ({ task, integrations, closeSideBar, setRightSideBarMode }) => {
  const dispatch = useAppDispatch();
  const [isUpdating, setIsUpdating] = useState(false);

  const integration = integrations?.data.find((integration) => integration._uuid === task.parent_id);

  const [sourceConStrError, setSourceConStrError] = useState<string | undefined>(undefined);
  const [sourceConStr, setSourceConStr] = useState<string | null>(task?.source_conn_str ? task.source_conn_str : null);
  const handleSourceConStr = (value: string | null) => {
    if (!!sourceConStr) {
      setSourceConStrError(undefined);
    }
    setSourceConStr(value);
  };
  const onClickCopySourceConStr = () => {
    dispatch(pushSnackBarItemsSuccessDwhPage('Скопировано в буфер'));
  };

  const [targetConStrError, setTargetConStrError] = useState<string | undefined>(undefined);
  const [targetConStr, setTargetConStr] = useState<string | null>(task?.target_conn_str ? task.target_conn_str : null);
  const handleTargetConStr = (value: string | null) => {
    if (!!targetConStr) {
      setTargetConStrError(undefined);
    }
    setTargetConStr(value);
  };
  const onClickCopyTargetConStr = () => {
    dispatch(pushSnackBarItemsSuccessDwhPage('Скопировано в буфер'));
  };

  const onCancelClick = () => {
    if (closeSideBar) {
      closeSideBar();
    }
  };

  const updateTask = async () => {
    try {
      setIsUpdating(true);
      const params: UpdateIntegrationTaskParamsType = {
        taskUuid: task?._uuid || '',
        integrationUuid: integration?._uuid || '',
        source_conn_str: sourceConStr || '',
        target_conn_str: targetConStr || '',
      };
      const res = await serverApi.updateIntegrationTask(params);
      dispatch(pushSnackBarItemsSuccessDwhPage('Шаг интеграции сохранен'));
      if (res) {
        dispatch(updateIntegrationInStore(res));
        if (setRightSideBarMode) {
          setRightSideBarMode(DwhRightSideBarEnum.DisplayIntegrationOrTask);
        }
      }
    } catch (e) {
      onCancelClick();
      dispatch(pushSnackBarItemsErrorDwhPage('Ошибка сохранения интеграции'));
    } finally {
      setIsUpdating(false);
    }
  };

  const onUpdateClick = () => {
    if (!sourceConStr) {
      setSourceConStrError('Обязательное к заполнению поле');
    }
    if (!targetConStr) {
      setTargetConStrError('Обязательное к заполнению поле');
    }
    if (sourceConStr && targetConStr) {
      updateTask();
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <div className={classes.section}>
            <Text className={classes.sectionTitle}>{'Код'}</Text>
            <Text className={classes.sectionValue}>
              {integration?.integration_id ? integration.integration_id : '-'}
            </Text>
          </div>
          <div className={classes.section}>
            <Text className={classes.sectionTitle}>{'Интегрируемая система'}</Text>
            <Text className={classes.sectionValue}>{integration?.system?.name ? integration.system.name : '-'}</Text>
          </div>
          <div className={classes.section}>
            <Text className={classes.sectionTitle}>{'Объект источник'}</Text>
            <Text className={classes.sectionValue}>{integration?.source_object ? integration.source_object : '-'}</Text>
          </div>
          <CustomTextFieldWithCopyBtn
            label={'Строка подключения источника'}
            value={sourceConStr}
            onChange={handleSourceConStr}
            error={sourceConStrError}
            onClickCopy={onClickCopySourceConStr}
          />
          <div className={classes.section}>
            <Text className={classes.sectionTitle}>{'Объект получатель'}</Text>
            <Text className={classes.sectionValue}>{task.target_object ? task.target_object : '-'}</Text>
          </div>
          <CustomTextFieldWithCopyBtn
            label={'Строка подключения получателя'}
            value={targetConStr}
            onChange={handleTargetConStr}
            error={targetConStrError}
            onClickCopy={onClickCopyTargetConStr}
          />
          <div className={classes.section}>
            <Text className={classes.sectionTitle}>{'Дата и время последней загрузки'}</Text>
            <Text className={classes.sectionValue}>
              {task.ts_last_load ? formatDateDDMMYYYYHHMM(new Date(task.ts_last_load)) : '-'}
            </Text>
          </div>
        </div>
      </div>
      <Delimiter />
      <div className={classes.bottomButtons}>
        <Button size={'s'} view={'ghost'} label={'Отменить'} style={{ width: '193px' }} onClick={onCancelClick} />
        <Button
          size={'s'}
          label={'Сохранить'}
          style={{ width: '193px' }}
          onClick={onUpdateClick}
          loading={isUpdating}
        />
      </div>
    </div>
  );
};

export default DwhEditTask;
