import { SortingParamsType } from '../../shared-front/types/types';

export const getSortingDirectionByName = (sortingParams: SortingParamsType[] | undefined, sortName: string) => {
  if (!sortingParams || !sortingParams.length) {
    return false;
  }
  const sortingByName = sortingParams?.find((sorting) => sorting.sortName === sortName);
  if (!sortingByName) {
    return false;
  }
  return sortingByName.sortDirection;
};
