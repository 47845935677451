import React, { useEffect, useRef, useState } from 'react';
import classes from './DwhCreateIntegration.module.css';
import { DwhRightSideBarEnum } from '../../DwhPage';
import { InfoSystemType } from '../../../../types/types';
import { Loader } from '@consta/uikit/Loader';
import { serverApi } from '../../../../api/serverApi';
import { useAppDispatch } from '../../../../hooks/hooks';
import { pushSnackBarItemsErrorDwhPage } from '../../../../store/dwhPageSlice';
import DwhCreateIntegrationForm from './DwhCreateIntegrationForm/DwhCreateIntegrationForm';
import Delimiter from '../../../../shared-front/Delimiter/Delimiter';
import DwhRightSideBarInfoRow from '../DwhRightSideBarInfoRow/DwhRightSideBarInfoRow';

interface IDwhCreateIntegrationProps {
  rightSideBarMode: DwhRightSideBarEnum | undefined;
  setRightSideBarMode?: (mode: DwhRightSideBarEnum | undefined) => void;
  closeSideBar?: () => void;
}

const DwhCreateIntegration: React.FC<IDwhCreateIntegrationProps> = ({
  setRightSideBarMode,
  rightSideBarMode,
  closeSideBar,
}) => {
  const dispatch = useAppDispatch();
  const refFirstLoad = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [infoSystems, setInfoSystems] = useState<InfoSystemType[] | undefined>(undefined);

  useEffect(() => {
    if (!infoSystems && !refFirstLoad.current) {
      setIsLoading(true);
      refFirstLoad.current = true;
      serverApi
        .getInfoSystemsDictionary('?sort=name')
        .then((res) => {
          setInfoSystems(res);
        })
        .catch(() => {
          dispatch(pushSnackBarItemsErrorDwhPage('Ошибка получения списка ИС'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [dispatch, infoSystems]);

  return (
    <div className={classes.container}>
      <DwhRightSideBarInfoRow
        activeIntegrationOrTaskUuid={undefined}
        rightSideBarMode={rightSideBarMode}
        setRightSideBarMode={setRightSideBarMode}
        closeSideBar={closeSideBar}
      />
      <Delimiter />
      {isLoading ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : infoSystems?.length ? (
        <DwhCreateIntegrationForm
          infoSystems={infoSystems}
          closeSideBar={closeSideBar}
          setRightSideBarMode={setRightSideBarMode}
        />
      ) : null}
    </div>
  );
};

export default DwhCreateIntegration;
