import React from 'react';
import classes from './DwhEditIntegrationOrTask.module.css';
import { DwhRightSideBarEnum } from '../../DwhPage';
import { useAppSelector } from '../../../../hooks/hooks';
import { selectorIntegrationsDwhPage } from '../../../../store/dwhPageSlice';
import { getIntegrationByIdFromIntegrations } from '../../../../utils/DwhPageUtils/getIntegrationByIdFromIntegrations';
import { getTaskByIdFromIntegrations } from '../../../../utils/DwhPageUtils/getTaskByIdFromIntegrations';
import DwhEditTask from './DwhEditTask/DwhEditTask';
import DwhEditIntegration from './DwhEditIntegration/DwhEditIntegration';
import DwhRightSideBarInfoRow from '../DwhRightSideBarInfoRow/DwhRightSideBarInfoRow';

interface IDwhEditIntegrationOrTaskProps {
  activeIntegrationOrTaskUuid: string | undefined;
  rightSideBarMode: DwhRightSideBarEnum | undefined;
  setRightSideBarMode?: (mode: DwhRightSideBarEnum | undefined) => void;
  closeSideBar?: () => void;
}

const DwhEditIntegrationOrTask: React.FC<IDwhEditIntegrationOrTaskProps> = ({
  activeIntegrationOrTaskUuid,
  setRightSideBarMode,
  rightSideBarMode,
  closeSideBar,
}) => {
  const integrations = useAppSelector(selectorIntegrationsDwhPage);
  const integration = getIntegrationByIdFromIntegrations(integrations, activeIntegrationOrTaskUuid);
  const task = integration ? undefined : getTaskByIdFromIntegrations(integrations, activeIntegrationOrTaskUuid);

  return (
    <div className={classes.container}>
      <DwhRightSideBarInfoRow
        activeIntegrationOrTaskUuid={activeIntegrationOrTaskUuid}
        rightSideBarMode={rightSideBarMode}
        setRightSideBarMode={setRightSideBarMode}
        closeSideBar={closeSideBar}
      />
      {task ? (
        <DwhEditTask
          task={task}
          integrations={integrations}
          closeSideBar={closeSideBar}
          setRightSideBarMode={setRightSideBarMode}
        />
      ) : integration ? (
        <DwhEditIntegration
          integration={integration}
          closeSideBar={closeSideBar}
          setRightSideBarMode={setRightSideBarMode}
        />
      ) : null}
    </div>
  );
};

export default DwhEditIntegrationOrTask;
