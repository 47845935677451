import { DataRangeType } from '../Components/FilterData/FilterData';
import React from 'react';
import {
  CardDataPreviewServerResponseType,
  CardScriptServerResponseType,
  GetCatalogItemsServerResponseType,
  MetaResponseType,
  Object1CAttributeServerResponseType,
  TableViewTableFunctionAttributeServerResponseType,
} from '../api/serverResponse';
import { CardTabType } from '../Components/CardTabsRow/CardTabsRow';
import { ColumnDef } from '@tanstack/react-table';
import { InnerRowsType, TableColumnType } from '../shared-front/Table/Table';
import { FilterParamsType, SortingParamsType } from '../shared-front/types/types';

export type LogEventParamsType = {
  event_type: 'view';
  http_path: string | null;
  http_query_string: string | null;
};

export type CatalogSectionType = {
  id: string;
  shortName: string;
  label: string;
  slug: string;
  disabled?: boolean;
};

export type BlockSystemType = {
  _uuid: string;
  name?: string | null;
};

export type BusinessSystemType = {
  _uuid: string;
  name?: string | null;
  full_name?: string | null;
};

export type BusinessSolutionType = {
  _uuid: string;
  name?: string | null;
  full_name?: string | null;
};

export type OrganizationSystemType = {
  _uuid: string;
  name?: string | null;
  full_name?: string | null;
};

export type TechnicalSystemType = {
  _uuid: string;
  name?: string | null;
  full_name?: string | null;
};

export type CatalogItemType = {
  ord?: string | null;
  name?: string | null;
  comment?: string | null;
  description?: string | null;
  description_name?: string | null;
  description_value?: string | null;
  object_kind?: string | null;
  unified_object?: string | null;
  level_1?: string | null;
  level_2?: string | null;
  level_3?: string | null;
  full_name?: string | null;
  owner?: string | null;
  connector_type?: string | null;
  icon?: string | null;
  system?: OrganizationSystemType | null;
  organization?: OrganizationSystemType | null;
  _hash_key?: string | null;
  _uuid?: string | null;
  color_group?: string | null;
  uuid_system?: string | null;
  uuid_connector_type?: string | null;
  uuid_organization?: string | null;
  hash_db?: string | null;
  hash_schema?: string | null;
  hash_object?: string | null;
  hash_object_attr?: string | null;
  attr_type?: string | null;
  attr?: string | null;
  unified_object_slug?: string | null;
  code?: string | null;
  tech_name_edm?: string | null;
  en_name?: string | null;
  block?: BlockSystemType | null;
  link_edm?: string | null;
  integration_dwh?: boolean | null;
  scan_metadata?: boolean | null;
  _create_user?: UserBaseType | null;
  _alter_user?: UserBaseType | null;
  _ts_alter?: string | null;
  _ts_create?: string | null;
  _alter_ts?: string | null;
  _create_ts?: string | null;
  ts_alter?: string | null;
  ts_create?: string | null;
  business_solution?: BusinessSolutionType | null;
  business_system?: OrganizationSystemType | null;
  technical_system?: TechnicalSystemType | null;
  db?: string | null;
  schema?: string | null;
  object?: string | null;
};

export enum FilterTypeEnum {
  multiFilter = 'multiFilter',
  dataFilter = 'dataFilter',
  groupFilter = 'groupFilter',
}

export type MultiFilterTagType = {
  filterType: FilterTypeEnum.multiFilter;
  filterName: string;
  filterTile: string;
  itemUuid?: string;
  itemValue?: string;
  itemIsChecked?: boolean;
};

export type DataFilterTagType = {
  filterType: FilterTypeEnum.dataFilter;
  filterName: string;
  filterTile: string;
  filterDataRange?: DataRangeType;
};

export type GroupFilterTagType = {
  filterType: FilterTypeEnum.groupFilter;
  filterName: string;
  filterTile: string;
  filterIsChecked?: boolean;
};

export type SortingItemType = {
  id: number;
  label: string;
  ico: React.ReactNode;
  icoItem: React.ReactNode;
  sortName: string;
  sortDirection: string;
};

export type HierarchyFilterGroupType = {
  type: string;
  name: string;
  title: string;
  group: string;
  groupTitle: string;
  hasDictionary: string;
  children?: HierarchyFilterGroupType[];
  items?: HierarchyItemType[];
};

export type HierarchyItemType = {
  uuid: string;
  name: string;
  has_checkbox: boolean;
  has_childs: boolean;
  type: string;
  isOpen: boolean;
  isChecked: boolean;
  children?: HierarchyItemType[];
};

export type TypeBookmarkType = 'catalog' | 'rdb' | 'cdb' | 'odata1c' | 'api' | 'system';
export type SourceSlugType =
  | 'catalog'
  | 'system'
  | 'table'
  | 'view'
  | 'function'
  | 'procedure'
  | 'table_function'
  | 'materialized_view'
  | 'dictionary'
  | 'db'
  | 'attr'
  | 'schema'
  | 'trigger'
  | 'directory'
  | 'document'
  | 'table_part'
  | 'constant'
  | 'exchange_plan'
  | 'document_journal'
  | 'chart_of_characteristic_types'
  | 'chart_of_accounts'
  | 'chart_of_calculation_type'
  | 'calculation_register'
  | 'information_register'
  | 'accumulation_register'
  | 'accounting_register'
  | 'business_process'
  | 'task'
  | 'odata1c_attr'
  | 'odata1c_enum';

export type ObjectsDataType = {
  [key: string]: GetCatalogItemsServerResponseType;
};

export type ObjectTypesType = {
  name: string;
  objectType: string;
  hasObjects: boolean;
};

export type FilterDictionaryItemType = {
  uuid: string;
  name: string;
  slug?: string;
  isChecked?: boolean;
  id?: string;
  value?: string;
};

export type FilterDictionaryType = {
  filterName: string;
  data?: FilterDictionaryItemType[];
  meta?: MetaResponseType;
  searchValue?: string;
};

export type ObjectsBookmarkType = {
  activeObjectTypeTab?: CatalogSectionType | undefined;
  isLoadingObjects?: boolean;
  objectTypes?: [ObjectTypesType];
  objectsData?: ObjectsDataType;
  objectsTableColumns?: {
    [key: string]: TableColumnType[];
  };
  searchValue?: string;
  objectsSorting?: SortingParamsType[];
  objectsFiltering?: FilterParamsType[];
  objectsFilteringDictionaries?: FilterDictionaryType[];
};

export type ColumnGenericType = ColumnDef<{ [key: string]: unknown }> & {
  title: string;
  isShow: boolean;
  accessorKey: string;
};

export type EdmDataType = {
  link?: string | null | undefined;
  business_description?: string | null | undefined;
  description?: string | null | undefined;
};

export type EdmDataElementType = {
  _hash_key: string;
  name?: string | null | undefined;
  link?: string | null | undefined;
  description?: string | null | undefined;
};

export type BookmarkType = {
  key: number;
  label: string;
  type: TypeBookmarkType;
  slug: SourceSlugType;
  hash: string;
  activeTab?: CardTabType | undefined;
  data?: ResponseBookmarkType;
  objects?: ObjectsBookmarkType;
  tableColumns?: ColumnGenericType[];
  edm?: EdmDataType;
  data_elements?: EdmDataElementType[];
};

export type BookmarkDescriptionType = {
  _uuid: string;
  name?: string | null;
  value?: string | null;
};

export type BookmarkSystemType = {
  _uuid: string;
  name?: string | null;
  description?: string | null;
  integration_dwh?: boolean | null;
  scan_metadata?: boolean | null;
  business_system?: BusinessSystemType | null;
  business_solution?: BusinessSolutionType | null;
  technical_system?: TechnicalSystemType | null;
  organization?: OrganizationSystemType | null;
  asset?: string | null;
  _alter_ts?: string | null;
  unified_object?: BookmarkUnifiedObjectType;
  slug?: string | null;

  code?: string | null;
  full_name?: string | null;
  en_name?: string | null;
  tech_name_edm?: string | null;
  block?: BlockSystemType | null;
  link_edm?: string | null;
  _create_ts?: string | null;
  _create_user?: UserBaseType | null;
  _alter_user?: UserBaseType | null;
};

export type BookmarkUnifiedObjectType = {
  slug?: SourceSlugType;
  unified_name?: string;
  color_group?: number;
};

export type BookmarkHierarchyLevelType = {
  slug?: string;
  type?: string;
  uuid?: string;
  name?: string;
};

export type UserBaseType = {
  full_name: string;
  position?: string | null;
  icon?: string | null;

  department?: string | null;
  email?: string | null;
  login?: string | null;
  organization?: OrganizationSystemType | null;
};

export type BookmarkBaseType = {
  _uuid: string;
  name?: string | null;
  object?: string | null;
  _alter_ts?: string | null;
  _create_ts?: string | null;
  source_type?: string | null;
  source_icon?: string | null;
  _create_user?: string | null;
  _alter_user?: string | null;
  system: BookmarkSystemType;
  description?: string | null;
  short_description?: string | null;
  unified_object?: BookmarkUnifiedObjectType;
  hierarchical_structure?: {
    level_1: BookmarkHierarchyLevelType;
    level_2: BookmarkHierarchyLevelType;
    level_3: BookmarkHierarchyLevelType;
    level_4: BookmarkHierarchyLevelType;
  } | null;
  connection_name?: string | null;
  last_scanned_at?: string | null;
  created_on_portal_ts?: string | null;
  updated_on_portal_ts?: string | null;
};

export type BookmarkRdbCdbType = BookmarkBaseType & {
  owner?: string | null;
  comment?: string | null;
  encoding?: string | null;
  engine?: string | null;
  schemas_count?: string | null;
  tables_count?: string | null;
  views_count?: string | null;
  other_objects_count?: string | null;
  altered_in_source_system_at?: string | null;
  created_in_source_system_at?: string | null;
  type?: TypeBookmarkType;
  kind?: string | null;
  edm?: EdmDataType;
  data_elements?: EdmDataElementType[];
};

export type BookmarkSchemaType = BookmarkBaseType & {
  owner?: string | null;
  comment?: string | null;
  tables_count?: string | null;
  views_count?: string | null;
  other_objects_count?: string | null;
};

export type BookmarkTableViewTableFunctionType = BookmarkBaseType & {
  owner?: string | null;
  comment?: string | null;
  created_in_source_system_at?: string | null;
  altered_in_source_system_at?: string | null;
  kind?: string | null;
  engine?: string | null;
  attr_count?: string | null;
  attributesData?: TableViewTableFunctionAttributeServerResponseType;
  previewData?: CardDataPreviewServerResponseType;
  scriptData?: CardScriptServerResponseType;
  edm?: EdmDataType;
  data_elements?: EdmDataElementType[];
};

export type BookmarkAttributeType = BookmarkBaseType & {
  owner?: string | null;
  type?: string | null;
  not_null?: string | null;
  default_value?: string | null;
  num?: string | null;
  comment?: string | null;
  is_in_primary_key?: boolean | null;
  is_in_foreign_key?: boolean | null;
  altered_in_source_system_at?: string | null;
  unified_data_type?: string | null;
  edm?: EdmDataType;
  data_elements?: EdmDataElementType[];
};

export type BookmarkProcedureTriggerFunctionType = BookmarkBaseType & {
  owner?: string | null;
  kind?: string | null;
  unified_name?: string | null;
  altered_in_source_system_at?: string | null;
  scriptData?: CardScriptServerResponseType;
};

export type Bookmark1CObject = BookmarkBaseType & {
  odata_name?: string | null;
  kind?: string | null;
  attr_count?: string | null;
  attributesData?: Object1CAttributeServerResponseType;
  previewData?: CardDataPreviewServerResponseType;

  innerRows?: InnerRowsType<Object1CAttributeType>;
  openedRows?: (string | number)[];

  edm?: EdmDataType;
  data_elements?: EdmDataElementType[];
};

export type Bookmark1CAttributeType = BookmarkBaseType & {
  type?: string | null;
  not_null?: string | null;
  odata_name?: string | null;
  unified_data_type?: string | null;
  edm?: EdmDataType;
  data_elements?: EdmDataElementType[];
};

export type ResponseBookmarkType =
  | BookmarkRdbCdbType
  | BookmarkSystemType
  | BookmarkSchemaType
  | BookmarkTableViewTableFunctionType
  | BookmarkAttributeType
  | BookmarkProcedureTriggerFunctionType
  | Bookmark1CObject
  | Bookmark1CAttributeType;

export type AttributeTypeType = {
  name?: string | null;
  eng_name?: string | null;
  short_name?: string | null;
  description?: string | null;
};

export type TableViewTableFunctionAttributeType = {
  _uuid: string;
  name: string | null;
  unified_data_type: string | null;
  type: AttributeTypeType;
  attribute_type: string | null;
  not_null?: string | null;
  default_value?: string | null;
  num?: string | null;
  comment?: string | null;
  short_description?: string | null;
  description?: string | null;
  is_in_primary_key?: boolean;
  is_in_foreign_key?: boolean;
};

export type Object1CAttributeNameType = {
  hash: string;
  label?: string | null;
  slug: string;
  type: string;
};

export type Object1CAttributeType = {
  _uuid: string;
  name: Object1CAttributeNameType;
  unified_data_type?: string | null;
  short_description?: string | null;
  description?: string | null;
  type?: string | null;
  not_null?: string | null;
  children?: string[];
};

export type PreviewDataType = {
  columns?: { [key: string]: string }[] | null;
  columns_meta?: string[] | null;
};

export type ScriptDataType = {
  code?: string | null;
};

export type UpdateDescriptionParamsType = {
  short_description: string | null;
  description: string | null;
};

export type UserInfoPermissionType = {
  name: string;
  slug: string;
};

export type UserInfoType = {
  username: string;
  permissions: UserInfoPermissionType[];
  version?: string;
};

export type OrganizationAndSubSystemType = {
  _uuid: string;
  uuid: string;
  name?: string | null;
  full_name?: string | null;
  code?: string | null;
};

export type InfoSystemType = {
  _uuid: string;
  name?: string | null;
  en_name?: string | null;
  description?: string | null;
  business_system?: OrganizationAndSubSystemType | null;
  business_solution?: OrganizationAndSubSystemType | null;
  technical_system?: OrganizationAndSubSystemType | null;
  organization?: OrganizationAndSubSystemType | null;
  block?: OrganizationAndSubSystemType | null;
  integration_dwh?: boolean | null;
  scan_metadata?: boolean | null;
  _create_ts?: string | null;
  _create_user?: UserBaseType | null;
  _alter_ts?: string | null;
  _alter_user?: UserBaseType | null;

  code?: string | null;
  full_name?: string | null;
  tech_name_edm?: string | null;
  link_edm?: string | null;
  slug?: string | null;
};

export type OrganizationsAndSubSystemsType = {
  businessSystems: OrganizationAndSubSystemType[];
  businessSolutions: OrganizationAndSubSystemType[];
  technicalSystems: OrganizationAndSubSystemType[];
  organizations: OrganizationAndSubSystemType[];
  blocks: OrganizationAndSubSystemType[];
};

export type StoreSnackBarItemType = {
  key: number | string;
  message: string;
  status: string;
  autoClose?: number;
};

export type ConnectionSystemType = {
  _uuid: string;
  name?: string | null;
  description?: string | null;
  integration_dwh?: boolean | null;
  scan_metadata?: boolean | null;
  business_system?: BusinessSystemType | null;
  business_solution?: BusinessSolutionType | null;
  technical_system?: string | null;
  organization?: OrganizationSystemType | null;
  asset?: string | null;
  _alter_ts?: string | null;
  unified_object?: BookmarkUnifiedObjectType | null;
};

export type ConnectionConnectorType = {
  _uuid: string;
  name?: string | null;
  slug?: string | null;
  driver?: string | null;
  description?: string | null;
  connection_source?: string | null;
  airflow_provider?: string | null;
};

export type InfoSystemConnectionSettingsType = {
  name?: string | null;
  _uuid?: string | null;
};

export type ConnectionSettingsItemType = {
  _uuid: string;
  key: string;
  value: boolean | string | number | null | ConnectionSettingsItemType[] | InfoSystemConnectionSettingsType;
  isChecked?: boolean;
};

export type ConnectionSourceType = {
  _uuid: string;
  name?: string | null;
  slug?: string | null;
  description?: string | null;
  icon?: string | null;
  card_view?: 'db' | 'oracle' | 'odata' | null;
  default_settings?: ConnectionSettingsItemType[];
};

export type ConnectionAssetType = {
  _uuid: string;
  name?: string | null;
  uuid_connection?: string | null;
  description?: string | null;
  is_send_to_edm?: boolean | null;
  scanner_id?: string | null;
  preview_scanner_id?: string | null;
  settings: ConnectionSettingsItemType[];
};

export type ConnectionType = {
  _uuid: string;
  is_broken?: boolean | null;
  name?: string | null;
  description?: string | null;
  airflowConnId?: string | null;
  source_version?: string | null;
  host?: string | null;
  source_name?: string | null;
  scanner_id?: string | null;
  preview_scanner_id?: string | null;
  system?: ConnectionSystemType | null;
  connector_type?: ConnectionConnectorType | null;
  source_type?: ConnectionSourceType | null;
  assets?: ConnectionAssetType[] | null;
  _create_user?: UserBaseType | null;
  _alter_user?: UserBaseType | null;
  _create_ts?: string | null;
  _alter_ts?: string | null;
  password?: string | null;
  version?: string | null;
};

export type ItemComboboxType = {
  label: string;
  id: number | string;
  uuid?: string;
};

export type ItemComboboxConnectionAssetType = ItemComboboxType & {
  slug?: 'pass' | 'partly' | 'no_access';
};

export type ConnectionDetailType = ConnectionType & {
  port?: string | null;
  user?: string | null;
  instance?: string | null;
  url?: string | null;
  scanSchedule?: string | null;
  previewSchedule?: string | null;
  settings?: ConnectionSettingsItemType[];
  scan_all?: boolean | null;
};

export type LaunchHistoryItemType = {
  _uuid: string;
  name?: string | null;
  type?: string | null;
  objects?: string | null;
  status?: 'Ошибка' | 'Успешно' | 'Сканирование';
  airflow?: string | null;
  user?: string | null;
  ts_start?: string | null;
  ts_end?: string | null;
  airflowConnId?: string | null;
  log?: string | null;
};

export type InfoSystemSourceType = {
  _uuid: string;
  name?: string | null;
  slug?: string | null;
  description?: string | null;
  icon?: string | null;
};

export type SystemIntegrationType = {
  _uuid: string;
  name?: string | null;
  en_name?: string | null;
  description?: string | null;
  slug?: string | null;
  full_name?: string | null;
  tech_name_edm?: string | null;
  code?: number | null;
  link_edm?: string | null;
  integration_dwh?: boolean | null;
  scan_metadata?: boolean | null;
  business_system?: string | null;
  business_solution?: string | null;
  technical_system?: string | null;
  organization?: string | null;
  block?: string | null;
  asset?: string | null;
  _create_ts?: string | null;
  _create_user?: UserBaseType | null;
  _alter_ts?: string | null;
  _alter_user?: UserBaseType | null;
  unified_object?: string | null;
  color_group?: string | null;
  unified_object_slug?: string | null;
  connection_name?: string | null;
};

export type TaskIntegrationType = {
  _uuid: string;
  _hash_key?: string | null;
  _external_key?: string | null;
  parent_id?: string | null;
  source_object?: string | null;
  target_object?: string | null;
  source_conn_str?: string | null;
  target_conn_str?: string | null;
  ts_last_load?: string | null;
  description?: string | null;
  rating?: number | null;
  task_id?: string | null;
  integration_id?: string | null;
  system?: SystemIntegrationType | null;
  _create_user?: UserBaseType | null;
  _create_ts?: string | null;
  _alter_user?: UserBaseType | null;
  _alter_ts?: string | null;
};

export type IntegrationType = {
  _uuid: string;
  _hash_key?: string | null;
  _external_key?: string | null;
  integration_id?: string | null;
  source_object?: string | null;
  description?: string | null;
  was_obtained_from_logs?: boolean | null;
  status?: string | null;
  error_str?: string | null;
  responsible?: UserBaseType | null;
  developer?: UserBaseType | null;
  system?: SystemIntegrationType | null;
  tasks: TaskIntegrationType[] | null;
  _create_user?: UserBaseType | null;
  _create_ts?: string | null;
  _alter_user?: UserBaseType | null;
  _alter_ts?: string | null;
};
