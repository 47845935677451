import React from 'react';
import classes from './DwhControlRow.module.css';
import { LeftSideBarHandleType } from '../../../shared-front/LeftSideBar/LeftSideBar';
import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import TextSearchArea from '../../../shared-front/TextSearchArea/TextSearchArea';
import { IconSettings } from '@consta/uikit/IconSettings';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { selectorSearchValueDwhPage, setSearchValueDwhPage } from '../../../store/dwhPageSlice';
import { DwhRightSideBarEnum } from '../DwhPage';
import { useGetIntegrations } from '../../../hooks/useGetIntegrations';

interface IDwhControlRowProps {
  refSidebar: React.RefObject<LeftSideBarHandleType>;
  setRightSideBarMode: (mode: DwhRightSideBarEnum | undefined) => void;
}

const DwhControlRow: React.FC<IDwhControlRowProps> = ({ refSidebar, setRightSideBarMode }) => {
  const dispatch = useAppDispatch();
  const searchValue = useAppSelector(selectorSearchValueDwhPage);
  const getIntegrations = useGetIntegrations();

  const setSearchValue = (value: string) => {
    dispatch(setSearchValueDwhPage(value));
  };

  const onSearch = (value: { searchValue?: string }) => {
    getIntegrations(value);
  };

  const onClickButtonCreateIntegration = () => {
    setRightSideBarMode(DwhRightSideBarEnum.CreateIntegration);
    const isSidebarOpen = refSidebar.current?.getIsSidebarOpen();
    if (!isSidebarOpen) {
      refSidebar.current?.openSidebar();
    }
  };

  const onClickButtonTableTune = () => {
    setRightSideBarMode(DwhRightSideBarEnum.TuneTable);
    const isSidebarOpen = refSidebar.current?.getIsSidebarOpen();
    if (!isSidebarOpen) {
      refSidebar.current?.openSidebar();
    }
  };

  return (
    <div className={classes.container}>
      <Button label="Создать" view="secondary" iconLeft={IconAdd} size={'s'} onClick={onClickButtonCreateIntegration} />
      <div className={classes.rightColumn}>
        <TextSearchArea searchValue={searchValue} setSearchValue={setSearchValue} onSearch={onSearch} />
        <Button
          label="Настройки"
          view="ghost"
          size={'s'}
          iconLeft={IconSettings}
          onlyIcon={true}
          onClick={onClickButtonTableTune}
        />
      </div>
    </div>
  );
};

export default DwhControlRow;
