import React, { useEffect } from 'react';
import classes from './DwhTable.module.css';
import { LeftSideBarHandleType } from '../../../shared-front/LeftSideBar/LeftSideBar';
import { DwhRightSideBarEnum } from '../DwhPage';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  selectorIsLoadingIntegrationsDwhPage,
  selectorTableColumnsDwhPage,
  setTableColumnsDwhPage,
} from '../../../store/dwhPageSlice';
import { INTEGRATIONS_TABLE_COLUMNS } from '../../../Templates/integrationsTableColumns';
import { Loader } from '@consta/uikit/Loader';
import TableWrapper from '../../../Components/TableWrapper/TableWrapper';
import { Table } from '../../../shared-front/Table/Table';
import { getShowColumns } from '../../../shared-front/Table/utils/getShowColumns';
import { useColumnsStore } from '../../../hooks/useColumnsStore';
import { getShortColumns } from '../../../shared-front/Table/utils/getShortColumns';
import { useGetIntegrationsTableData } from '../../../hooks/useGetIntegrationsTableData';

interface IDwhTableProps {
  refSidebar: React.RefObject<LeftSideBarHandleType>;
  setRightSideBarMode: (mode: DwhRightSideBarEnum | undefined) => void;
  activeIntegrationOrTaskUuid: string | undefined;
  setActiveIntegrationOrTaskUuid: (uuid: string | undefined) => void;
}

const columnsTemplate = INTEGRATIONS_TABLE_COLUMNS;

const DwhTable: React.FC<IDwhTableProps> = ({
  refSidebar,
  setRightSideBarMode,
  setActiveIntegrationOrTaskUuid,
  activeIntegrationOrTaskUuid,
}) => {
  const dispatch = useAppDispatch();
  const getIntegrationsTableData = useGetIntegrationsTableData();
  const columnsStore = useAppSelector(selectorTableColumnsDwhPage);
  const columns = useColumnsStore({ columnsStore, columnsTemplate });
  const isLoadingIntegrations = useAppSelector(selectorIsLoadingIntegrationsDwhPage);

  const tableData = getIntegrationsTableData();

  useEffect(() => {
    if (!columnsStore.length) {
      dispatch(setTableColumnsDwhPage(getShortColumns(columnsTemplate)));
    }
  }, [dispatch, columnsStore.length]);

  const onRowClick = (originalRow: { [key: string]: unknown }) => {
    setActiveIntegrationOrTaskUuid(originalRow.id as string);
    setRightSideBarMode(DwhRightSideBarEnum.DisplayIntegrationOrTask);
    const isSidebarOpen = refSidebar.current?.getIsSidebarOpen();
    if (!isSidebarOpen) {
      refSidebar.current?.openSidebar();
    }
  };

  return (
    <div className={classes.container}>
      {isLoadingIntegrations ? (
        <Loader />
      ) : (
        <TableWrapper columns={columns} height={'100%'}>
          <Table
            data={tableData}
            columns={getShowColumns(columns)}
            onRowClick={onRowClick}
            isColumnsResizable={true}
            isRowHover={true}
            highlightedRowId={activeIntegrationOrTaskUuid}
          />
        </TableWrapper>
      )}
    </div>
  );
};

export default DwhTable;
