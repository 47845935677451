import React, { useState } from 'react';
import classes from './DateFilter.module.css';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { DatePicker, DatePickerPropValue } from '@consta/uikit/DatePicker';
import { Text } from '@consta/uikit/Text';
import { DateRange } from '@consta/uikit/__internal__/src/utils/types/Date';

const items = ['Одна дата', 'Диапазон'];

export type InputType = 'date' | 'date-range';

interface IDateFilterProps {
  dateRange: DateRange;
  onChangeDateRange: (dataRange: DateRange, inputType?: InputType) => void;
  initialInputType?: InputType;
  mode?: 'sidebar' | 'table';
  resetFilterFn?: () => void;
}

const DateFilter: React.FC<IDateFilterProps> = ({
  dateRange,
  onChangeDateRange,
  resetFilterFn,
  initialInputType,
  mode = 'sidebar',
}) => {
  const [inputType, setInputType] = useState<InputType>(initialInputType || (dateRange[1] ? 'date-range' : 'date'));
  const choiceGroupValue = inputType === 'date' ? items[0] : items[1];
  const datePickerValue = inputType === 'date' ? dateRange[0] : dateRange;

  const inputSize = mode === 'sidebar' ? 'm' : 's';
  const choiceGroupClass = mode === 'sidebar' ? classes.choiceGroup_sidebar : classes.choiceGroup_table;

  const onChangeValue = ({ value }: { value: string }) => {
    const inputType = value === items[0] ? 'date' : 'date-range';
    onChangeDateRange([dateRange[0], undefined], inputType);
    setInputType(inputType);
  };

  const handleChangeDateRange = ({ value }: { value: DatePickerPropValue<'date' | 'date-range'> }) => {
    if (Array.isArray(value)) {
      onChangeDateRange(value ? [value[0], value[1]] : [undefined, undefined], inputType);
    } else {
      onChangeDateRange([value!, undefined], inputType);
    }
  };

  return (
    <div className={classes.container}>
      {resetFilterFn && (
        <div className={classes.resetBtnWrapper}>
          {!!(dateRange[0] || dateRange[1]) && (
            <Text onClick={() => resetFilterFn()} size="s" view="link" cursor="pointer" className={classes.resetBtn}>
              Сбросить
            </Text>
          )}
        </div>
      )}
      <div className={classes.container}>
        <ChoiceGroup
          view="ghost"
          size={inputSize}
          value={choiceGroupValue}
          onChange={onChangeValue}
          items={items}
          getItemLabel={(item) => item}
          multiple={false}
          name="ChoiceDataType"
          className={choiceGroupClass}
        />
        <div className={classes.row}>
          <DatePicker
            size={inputSize}
            type={inputType}
            value={datePickerValue}
            onChange={handleChangeDateRange}
            style={{ width: '100%' }}
          />
        </div>
      </div>
    </div>
  );
};

export default DateFilter;
