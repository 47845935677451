import React, { useState } from 'react';
import classes from './TableColumnDateFilter.module.css';
import { FilterParamsType } from '../../types/types';
import { DateRange } from '@consta/uikit/__internal__/src/utils/types/Date';
import { Button } from '@consta/uikit/Button';
import DateFilter from '../../Filters/DateFilter/DateFilter';
import { formatDateYYYYMMDD } from '../../utils/formatDateYYYYMMDD';

interface ITableColumnDateFilterProps {
  filterName: string;
  filterValue?: string;
  applyFilter: (filterParams: FilterParamsType) => void;
}

const getDataRangeFromString = (filterValueString?: string): DateRange => {
  if (!filterValueString) {
    return [] as DateRange;
  }
  const [fromStr, toStr] = filterValueString.split(':');
  return [new Date(fromStr), toStr ? new Date(toStr) : undefined];
};

const getStringFromDataRange = (dataRange?: DateRange | undefined | null): string => {
  if (!dataRange || !dataRange[0]) {
    return '';
  }
  return `${formatDateYYYYMMDD(dataRange[0])}${dataRange[1] ? `:${formatDateYYYYMMDD(dataRange[1]!)}` : ''}`;
};

const TableColumnDateFilter: React.FC<ITableColumnDateFilterProps> = ({ filterName, filterValue, applyFilter }) => {
  const [localDataRange, setLocalDataRange] = useState<DateRange>(getDataRangeFromString(filterValue));

  const filterValueDataRange = getDataRangeFromString(filterValue);
  const initialInputType = filterValueDataRange && filterValueDataRange[1] !== undefined ? 'date-range' : 'date';

  const handleChangeDateRange = (newValue: DateRange, inputType?: 'date' | 'date-range') => {
    const [from, to] = newValue;
    const secondRangeItem = inputType === 'date' ? null : to;
    setLocalDataRange([from, secondRangeItem ? secondRangeItem : undefined]);
  };

  const handleSetFilter = () => {
    const stringLocalDataRange = getStringFromDataRange(localDataRange);
    applyFilter({ filterName, filterValue: stringLocalDataRange });
  };

  const handleResetFilter = () => {
    applyFilter({ filterName, filterValue: '' });
  };

  return (
    <div className={classes.filterContainer}>
      <div style={{ width: '100%' }}>
        <DateFilter
          dateRange={localDataRange}
          onChangeDateRange={handleChangeDateRange}
          resetFilterFn={handleResetFilter}
          initialInputType={initialInputType}
          mode="table"
        />
        <div className={classes.buttonsWrapper}>
          <Button label="Применить" view="primary" width="full" onClick={handleSetFilter} />
        </div>
      </div>
    </div>
  );
};

export default TableColumnDateFilter;
