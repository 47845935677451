import React from 'react';
import classes from './DwhRightSideBar.module.css';
import LeftSideBar, { LeftSideBarHandleType } from '../../../shared-front/LeftSideBar/LeftSideBar';
import { DwhRightSideBarEnum } from '../DwhPage';
import TableSettings from '../../../shared-front/TableSettings/TableSettings';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  clearSnackBarItemsDwhPage,
  selectorTableColumnsDwhPage,
  setTableColumnsDwhPage,
} from '../../../store/dwhPageSlice';
import { TableColumnType } from '../../../shared-front/Table/Table';
import { INTEGRATIONS_TABLE_COLUMNS } from '../../../Templates/integrationsTableColumns';
import DwhDisplayIntegrationOrTask from './DwhDisplayIntegrationOrTask/DwhDisplayIntegrationOrTask';
import DwhCreateIntegration from './DwhCreateIntegration/DwhCreateIntegration';
import DwhEditIntegrationOrTask from './DwhEditIntegrationOrTask/DwhEditIntegrationOrTask';

interface IDwhRightSideBarProps {
  refSidebar: React.RefObject<LeftSideBarHandleType>;
  rightSideBarMode: DwhRightSideBarEnum | undefined;
  setRightSideBarMode: (mode: DwhRightSideBarEnum | undefined) => void;
  activeIntegrationOrTaskUuid: string | undefined;
  setActiveIntegrationOrTaskUuid: (uuid: string | undefined) => void;
}
const defaultColumns = INTEGRATIONS_TABLE_COLUMNS;

const DwhRightSideBar: React.FC<IDwhRightSideBarProps> = ({
  refSidebar,
  rightSideBarMode,
  setRightSideBarMode,
  setActiveIntegrationOrTaskUuid,
  activeIntegrationOrTaskUuid,
}) => {
  const dispatch = useAppDispatch();
  const columnsStore = useAppSelector(selectorTableColumnsDwhPage);

  const setColumns = (columns: (object & TableColumnType)[]) => {
    dispatch(setTableColumnsDwhPage(columns));
  };

  const closeSidebarClick = () => {
    setRightSideBarMode(undefined);
    dispatch(clearSnackBarItemsDwhPage());
    if (activeIntegrationOrTaskUuid) {
      setActiveIntegrationOrTaskUuid(undefined);
    }
  };

  const closeSideBarExternalButton = () => {
    if (refSidebar.current?.closeSidebar) {
      refSidebar.current.closeSidebar();
    }
  };

  return (
    <div className={classes.container}>
      <LeftSideBar ref={refSidebar} closeSidebar={closeSidebarClick} isSecondaryBackground={true}>
        {rightSideBarMode === DwhRightSideBarEnum.TuneTable ? (
          <TableSettings
            columns={columnsStore}
            setColumns={setColumns}
            hideFooter={true}
            defaultColumns={defaultColumns}
          />
        ) : rightSideBarMode === DwhRightSideBarEnum.DisplayIntegrationOrTask ? (
          <DwhDisplayIntegrationOrTask
            activeIntegrationOrTaskUuid={activeIntegrationOrTaskUuid}
            setRightSideBarMode={setRightSideBarMode}
            rightSideBarMode={rightSideBarMode}
            closeSideBar={closeSideBarExternalButton}
          />
        ) : rightSideBarMode === DwhRightSideBarEnum.CreateIntegration ? (
          <DwhCreateIntegration
            setRightSideBarMode={setRightSideBarMode}
            rightSideBarMode={rightSideBarMode}
            closeSideBar={closeSideBarExternalButton}
          />
        ) : rightSideBarMode === DwhRightSideBarEnum.EditIntegrationOrTask ? (
          <DwhEditIntegrationOrTask
            activeIntegrationOrTaskUuid={activeIntegrationOrTaskUuid}
            setRightSideBarMode={setRightSideBarMode}
            rightSideBarMode={rightSideBarMode}
            closeSideBar={closeSideBarExternalButton}
          />
        ) : (
          <div style={{ width: 640 }} />
        )}
      </LeftSideBar>
    </div>
  );
};

export default DwhRightSideBar;
