import React from 'react';

import classes from './SystemCommonInfoTab.module.css';
import CollapseBlock from '../../../../../../Components/CollapseBlock/CollapseBlock';
import { Text } from '@consta/uikit/Text';
import CardsContentBlock from '../../../../../../Components/CardsContentBlock/CardsContentBlock';
import { BookmarkSystemType, BookmarkType } from '../../../../../../types/types';
import CardsRightColumnCard from '../../../../../../Components/CardsRightColumnCard/CardsRightColumnCard';
import TextWithMark from '../../../../../../Components/TextWithMark/TextWithMark';
import BlueLinkOpenInNewTab from '../../../../../../Components/BlueLinkOpenInNewTab/BlueLinkOpenInNewTab';
import ActionDateTimeWithUser from '../../../../../../Components/ActionDateTimeWithUser/ActionDateTimeWithUser';

interface IRdbCommonInfoTabProps {
  bookmark: BookmarkType;
}

const SystemCommonInfoTab: React.FC<IRdbCommonInfoTabProps> = ({ bookmark }) => {
  const data = bookmark.data as BookmarkSystemType;

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        <CollapseBlock title={'Основное'} isOpen={true}>
          <div style={{ marginTop: '-24px' }}>
            <CardsContentBlock
              title={'Код'}
              description={data?.code ? data?.code : '-'}
              className={classes.description}
            />
          </div>
          <CardsContentBlock
            title={'Полное наименование'}
            description={data?.full_name ? data?.full_name : '-'}
            className={classes.description}
          />
          <CardsContentBlock
            className={classes.description}
            title={'Техническое наименование'}
            description={data?.tech_name_edm ? data?.tech_name_edm : '-'}
            informer={'Наименование интегрируемой системы в EDM'}
          />
          <CardsContentBlock
            className={classes.description}
            title={'Краткое техническое наименование'}
            description={data?.slug ? data?.slug : '-'}
            informer={'Префикс системы используемый для разработки'}
          />
          <CardsContentBlock title={'Описание'} description={data?.description ? data?.description : '-'} />
          <CardsContentBlock
            title={'Бизнес система'}
            description={data?.business_system?.name ?? '-'}
            className={classes.description}
          />
          <CardsContentBlock
            title={'Бизнес решение'}
            description={data?.business_solution?.name ?? '-'}
            className={classes.description}
          />
          <CardsContentBlock
            title={'Техническая система'}
            description={data?.technical_system?.name ?? '-' ?? '-'}
            className={classes.description}
          />
          <div style={{ height: '60px' }} />
        </CollapseBlock>
      </div>
      <div className={classes.rightColumn}>
        <CardsRightColumnCard>
          <CardsContentBlock title={'Организация'}>
            <Text className={classes.rightCardDescription}>{data?.organization?.name ?? '-'}</Text>
          </CardsContentBlock>
          <CardsContentBlock title={'Блок'}>
            <Text className={classes.rightCardDescription}>{data?.block?.name ?? '-'}</Text>
          </CardsContentBlock>
          <CardsContentBlock title={'EDM'}>
            {data?.link_edm ? <BlueLinkOpenInNewTab linkUrl={data.link_edm} linkTitle={'Смотреть в EDM'} /> : '-'}
          </CardsContentBlock>
          <CardsContentBlock title={'Интеграция с хранилищем Дата-платформы'}>
            <TextWithMark
              isComplete={data?.integration_dwh}
              textDone={'Интегрировано с хранилищем'}
              textNotDone={'Не интегрировано с хранилищем'}
              isSecondaryColor={true}
            />
          </CardsContentBlock>
          <CardsContentBlock title={'Сканирование метаданных'}>
            <TextWithMark
              isComplete={data?.scan_metadata}
              textDone={'Сканировано'}
              textNotDone={'Не сканировано'}
              isSecondaryColor={true}
            />
          </CardsContentBlock>
          <ActionDateTimeWithUser
            actionTitle={'Создано'}
            actionDateTime={data?._create_ts}
            actionUser={data?._create_user}
          />
          <ActionDateTimeWithUser
            actionTitle={'Изменено'}
            actionDateTime={data?._alter_ts}
            actionUser={data?._alter_user}
          />
        </CardsRightColumnCard>
      </div>
    </div>
  );
};

export default SystemCommonInfoTab;
