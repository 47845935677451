import React, { useEffect, useRef, useState } from 'react';
import classes from './DwhPage.module.css';
import DwhControlRow from './DwhControlRow/DwhControlRow';
import { LeftSideBarHandleType } from '../../shared-front/LeftSideBar/LeftSideBar';
import Delimiter from '../../shared-front/Delimiter/Delimiter';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import {
  deleteSnackBarItemDwhPage,
  resetDwhPage,
  selectorErrorDwhPage,
  selectorIntegrationsDwhPage,
  selectorSnackBarItemsDwhPage,
} from '../../store/dwhPageSlice';
import { useGetIntegrations } from '../../hooks/useGetIntegrations';
import ModalError from '../../Components/ModalError/ModalError';
import { SnackBar } from '@consta/uikit/SnackBar';
import DwhPaginationRow from './DwhPaginationRow/DwhPaginationRow';
import DwhTable from './DwhTable/DwhTable';
import DwhRightSideBar from './DwhRightSideBar/DwhRightSideBar';

export enum DwhRightSideBarEnum {
  TuneTable = 'tune_table',
  DisplayIntegrationOrTask = 'display_integration_or_task',
  CreateIntegration = 'create_integration',
  EditIntegrationOrTask = 'edit_integration_or_task',
}

const DwhPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const refFirstUpload = useRef(false);
  const getIntegrations = useGetIntegrations();
  const integrations = useAppSelector(selectorIntegrationsDwhPage);
  const snackBarItemDwhPage = useAppSelector(selectorSnackBarItemsDwhPage);
  const errorDwhPage = useAppSelector(selectorErrorDwhPage);
  const refSidebar = useRef<LeftSideBarHandleType>(null);

  const [rightSideBarMode, setRightSideBarMode] = useState<DwhRightSideBarEnum | undefined>(undefined);
  const [activeIntegrationOrTaskUuid, setActiveIntegrationOrTaskUuid] = useState<string | undefined>(undefined);

  const errorMessage = errorDwhPage;

  const snackBarItems = snackBarItemDwhPage.map((snackBarItem) => ({
    ...snackBarItem,
    onClose: (item: { key: string }) => {
      dispatch(deleteSnackBarItemDwhPage({ key: item.key }));
    },
  }));

  useEffect(() => {
    document.title = `ТехМет Хран.Платформы`;
    return () => {
      dispatch(resetDwhPage());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!integrations && !refFirstUpload.current) {
      refFirstUpload.current = true;
      getIntegrations({});
    }
  }, [integrations, getIntegrations]);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <DwhControlRow refSidebar={refSidebar} setRightSideBarMode={setRightSideBarMode} />
        <Delimiter />
        <DwhTable
          refSidebar={refSidebar}
          setRightSideBarMode={setRightSideBarMode}
          activeIntegrationOrTaskUuid={activeIntegrationOrTaskUuid}
          setActiveIntegrationOrTaskUuid={setActiveIntegrationOrTaskUuid}
        />
      </div>
      <Delimiter />
      <DwhPaginationRow />
      <DwhRightSideBar
        refSidebar={refSidebar}
        rightSideBarMode={rightSideBarMode}
        setRightSideBarMode={setRightSideBarMode}
        activeIntegrationOrTaskUuid={activeIntegrationOrTaskUuid}
        setActiveIntegrationOrTaskUuid={setActiveIntegrationOrTaskUuid}
      />
      <ModalError errorMessage={errorMessage} />
      <div className={classes.snackBarContainer} style={{ zIndex: 1000 }}>
        <SnackBar items={snackBarItems} />
      </div>
    </div>
  );
};

export default DwhPage;
