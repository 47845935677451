import { FilterParamsType } from '../../shared-front/types/types';

export const getFilterValueByName = (filters: FilterParamsType[] | undefined, filterName: string) => {
  if (!filters || !filterName || filters.length === 0) {
    return undefined;
  }
  const filter = filters?.find((filter) => filter.filterName === filterName);
  if (!filter) {
    return undefined;
  }
  return filter.filterValue;
};
