import React, { useState } from 'react';

import classes from './TableColumnItemsFilter.module.css';
import useDebouncedFunction from '../../hooks/useDebounceFunction';
import { IconSearch } from '@consta/uikit/IconSearch';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';
import FilterItemsList from './FilterItemsList/FilterItemsList';

export type ItemsFilterFilterItemType = {
  id: string;
  value: string;
  isChecked?: boolean;
};

interface ITableColumnItemsFilterProps {
  items: ItemsFilterFilterItemType[];
  itemsPerPage?: number | null;
  itemsTotal?: number | null;
  searchTermValue: string | null;
  onChangeSearchTerm: (value: string | null) => void;
  onChangeFilterItem: (filterItem: ItemsFilterFilterItemType) => void;
  isSelectAll?: boolean;
  isLoadingSelectAll?: boolean;
  onSelectAllItems?: () => void;
  onItemListScroll?: () => void;
  resetFilterFn?: (e?: MouseEvent) => void;
  isLoading: boolean;
  maxListHeight?: string;
  fitHeightByItemsPerPage?: boolean;
  noSearchString?: boolean;
  selectedItemsNumber?: number | null;
}

const TableColumnItemsFilter: React.FC<ITableColumnItemsFilterProps> = ({
  items,
  searchTermValue,
  onChangeSearchTerm,
  onChangeFilterItem,
  onSelectAllItems,
  onItemListScroll,
  resetFilterFn,
  isLoading,
  maxListHeight,
  fitHeightByItemsPerPage,
  noSearchString,
  selectedItemsNumber,
  isLoadingSelectAll,
  itemsPerPage = null,
  itemsTotal = null,
}) => {
  const checkedItemsNumber = items.filter((item) => item.isChecked).length;

  const [localSearch, setSearchTerm] = useState<string | null>(searchTermValue);

  const debouncedSearchFn = useDebouncedFunction(onChangeSearchTerm, 500, true);

  const handleChangeSearchTerm = (value: string | null) => {
    setSearchTerm(value);
    debouncedSearchFn(value);
  };

  return (
    <div className={classes.container}>
      <div className={classes.resetBtnWrapper}>
        {!!selectedItemsNumber && resetFilterFn && (
          <Text onClick={() => resetFilterFn()} size="s" view="link" cursor="pointer" className={classes.resetBtn}>
            Сбросить
          </Text>
        )}
      </div>
      {!noSearchString && (
        <div className={classes.textField}>
          <TextField
            value={localSearch}
            onChange={({ value }) => handleChangeSearchTerm(value)}
            style={{ flexGrow: '1' }}
            leftSide={IconSearch}
            placeholder="Найти в списке"
            size={'s'}
            withClearButton={true}
          />
        </div>
      )}
      <FilterItemsList
        items={items}
        itemsPerPage={itemsPerPage}
        itemsTotal={itemsTotal}
        onChangeFilterItem={onChangeFilterItem}
        onSelectAllItems={onSelectAllItems}
        onItemListScroll={onItemListScroll}
        selectedItemsNumber={selectedItemsNumber ?? checkedItemsNumber}
        isLoading={isLoading}
        maxListHeight={maxListHeight}
        fitHeightByItemsPerPage={fitHeightByItemsPerPage}
        isLoadingSelectAll={isLoadingSelectAll}
      />
    </div>
  );
};

export default TableColumnItemsFilter;
