import React, { ReactElement } from 'react';

export type RouteType = {
  path: string;
  element: React.ReactNode;
};

export type MenuItemType = {
  label: string;
  href?: string;
  extUrl?: string;
  children?: MenuItemType[];
  icon?: ReactElement;
  disabled?: boolean;
  isDelimiter?: boolean;
};

export enum SortingDirectionEnum {
  asc = 'asc',
  desc = 'desc',
}

export type SortingParamsType = {
  sortName: string;
  sortDirection: SortingDirectionEnum;
};

export type FilterParamsType = {
  filterName: string;
  filterValue: string | undefined;
};
