import React from 'react';

import classes from './FilterItemsList.module.css';
import { Checkbox } from '@consta/uikit/Checkbox';
import { Loader } from '@consta/uikit/Loader';
import { Text } from '@consta/uikit/Text';
import classNames from 'classnames';
import { ItemsFilterFilterItemType } from '../TableColumnItemsFilter';

interface IFilterItemsList {
  items: ItemsFilterFilterItemType[];
  itemsPerPage: number | null;
  itemsTotal?: number | null;
  onChangeFilterItem: (filterItem: ItemsFilterFilterItemType) => void;
  isSelectAll?: boolean;
  onSelectAllItems?: () => void;
  onItemListScroll?: () => void;
  selectedItemsNumber: number;
  isLoading: boolean;
  maxListHeight?: string;
  fitHeightByItemsPerPage?: boolean;
  isLoadingSelectAll?: boolean;
}

const FilterItemsList: React.FC<IFilterItemsList> = ({
  items,
  itemsPerPage,
  itemsTotal,
  onChangeFilterItem,
  onSelectAllItems,
  onItemListScroll,
  selectedItemsNumber,
  isLoading,
  maxListHeight,
  fitHeightByItemsPerPage,
  isLoadingSelectAll,
}) => {
  const handleSelectAll = () => {
    onSelectAllItems && onSelectAllItems();
  };

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    e.stopPropagation();
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 1) {
      onItemListScroll && onItemListScroll();
    }
  };

  const isOnePage = itemsTotal === itemsPerPage;

  let heightByItemsPerPage;

  if (fitHeightByItemsPerPage && itemsPerPage) {
    heightByItemsPerPage = (itemsPerPage + 1) * 40;
  }

  const isSelectAll = items.filter((item) => item.isChecked).length === itemsTotal;
  const hasSelectedItem = !!items.find((item) => item.isChecked);

  return (
    <div
      className={classNames(classes.container, classes.scroll)}
      onScroll={handleScroll}
      style={{
        maxHeight: heightByItemsPerPage || maxListHeight,
        overflowY: isOnePage && fitHeightByItemsPerPage ? 'hidden' : undefined,
      }}
    >
      {items.length ? (
        <>
          {isLoadingSelectAll ? (
            <Loader className={classes.loaderSelectAll} />
          ) : (
            <>
              {onSelectAllItems && (
                <div className={classNames(classes.selectAll)}>
                  <Checkbox
                    checked={isSelectAll}
                    label="Выбрать все"
                    onChange={handleSelectAll}
                    intermediate={hasSelectedItem ? selectedItemsNumber !== itemsTotal : false}
                  />
                  {!!itemsTotal && <Text view="secondary">{`${selectedItemsNumber} из ${itemsTotal}`}</Text>}
                </div>
              )}
              {items?.map((item) => (
                <div key={item.id} className={classes.item}>
                  <Checkbox checked={item.isChecked} label={item.value} onChange={() => onChangeFilterItem(item)} />
                </div>
              ))}
            </>
          )}
        </>
      ) : (
        !isLoading && <div className={classes.item}>Ничего не найдено!</div>
      )}
      {((onItemListScroll && items.length) || isLoading || isLoadingSelectAll) && (
        <div className={classes.loaderWrapper}>{isLoading && <Loader className={classes.loader} />}</div>
      )}
    </div>
  );
};

export default FilterItemsList;
