import React, { useEffect, useRef, useState } from 'react';

import classes from './CustomTextField.module.css';
import { TextField } from '@consta/uikit/TextField';
import { Text } from '@consta/uikit/Text';

import iconInfoCircle from '../../assets/CatalogPage/iconInfoCircle.svg';
import iconEditBlue from '../../assets/CatalogPage/iconEditBlue.svg';
import { Button } from '@consta/uikit/Button';
import CustomHoverToolTip from '../CustomHoverToolTip/CustomHoverToolTip';
import CustomBlueTextButton from '../CustomBlueTextButton/CustomBlueTextButton';
import { Loader } from '@consta/uikit/Loader';
interface ICustomTextFieldProps {
  title: string | undefined;
  placeholder?: string;
  value: string | undefined;
  isSaving: boolean;
  isModeEdit: boolean | undefined;
  onIsModeEditChange: (isModeEdit: boolean) => void;
  onChangeValue: (value: string) => void;
  onSave: (value: string) => void;
}

const CustomTextField: React.FC<ICustomTextFieldProps> = ({
  title,
  value = '',
  placeholder = '',
  onSave,
  isSaving,
  isModeEdit,
  onIsModeEditChange,
  onChangeValue,
}) => {
  const startValueRef = useRef<string>();
  const [isClose, setIsClose] = useState(false);

  const handleChange = ({ value }: { value: string | null }) => {
    onChangeValue(value ? value : '');
  };

  const clickIsEditMode = () => {
    onIsModeEditChange(true);
    startValueRef.current = value;
  };

  const handlerCancel = () => {
    if (startValueRef.current) {
      onChangeValue(startValueRef.current);
      startValueRef.current = undefined;
    } else {
      onChangeValue('');
    }
    setIsClose(true);
  };

  const handlerSave = () => {
    onSave(value ?? '');
    onIsModeEditChange(false);
    startValueRef.current = undefined;
  };

  useEffect(() => {
    if (isClose) {
      onIsModeEditChange(false);
      setIsClose(false);
    }
  }, [isClose, onIsModeEditChange]);

  return (
    <div className={classes.container}>
      <div className={classes.titleRow}>
        <Text className={classes.title}>{title}</Text>
        <CustomHoverToolTip title={`Пользовательское описание.\nДоступно для общего редактирования`}>
          <img src={iconInfoCircle} className={classes.ico} alt={'infoBtn'} />
        </CustomHoverToolTip>
        {!isModeEdit && (
          <div className={classes.buttonEdit}>
            <Button
              size={'xs'}
              label="edit"
              view="clear"
              onlyIcon
              iconRight={() => <img src={iconEditBlue} alt="editIco" />}
              onClick={clickIsEditMode}
            />
          </div>
        )}
      </div>
      {isModeEdit ? (
        <>
          <TextField
            onChange={handleChange}
            value={value}
            type="textarea"
            cols={95}
            rows={6}
            placeholder={placeholder}
            size={'s'}
          />
          <div className={classes.buttonsRow}>
            {isSaving ? (
              <div className={classes.loaderContainer}>
                <div className={classes.loaderText}>Сохранение</div>
                <div>
                  <Loader size={'xs'} className={classes.loader} />
                </div>
              </div>
            ) : (
              <>
                <CustomBlueTextButton title={'Отменить'} onClick={handlerCancel} />
                <CustomBlueTextButton title={'Сохранить'} onClick={handlerSave} />
              </>
            )}
          </div>
        </>
      ) : (
        <>
          {value ? (
            <Text className={classes.value}>{value}</Text>
          ) : (
            <Text className={classes.placeholder}>{placeholder}</Text>
          )}
        </>
      )}
    </div>
  );
};

export default CustomTextField;
