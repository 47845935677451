import { FilterDictionaryType } from '../../types/types';

interface IUpdateFiltersParams {
  currentFiltersDictionaryParams: FilterDictionaryType[] | undefined;
  filterDictionaryForUpdate: FilterDictionaryType;
}

export const updateFilterDictionary = ({
  currentFiltersDictionaryParams,
  filterDictionaryForUpdate,
}: IUpdateFiltersParams) => {
  const newFiltersDictionaryParams: FilterDictionaryType[] = [];
  if (currentFiltersDictionaryParams && !!currentFiltersDictionaryParams.length) {
    if (currentFiltersDictionaryParams.find((filter) => filter.filterName === filterDictionaryForUpdate.filterName)) {
      currentFiltersDictionaryParams.forEach((curFilterParams) => {
        if (curFilterParams.filterName === filterDictionaryForUpdate.filterName) {
          if (filterDictionaryForUpdate.data || filterDictionaryForUpdate.searchValue) {
            newFiltersDictionaryParams.push(filterDictionaryForUpdate);
          }
        } else {
          newFiltersDictionaryParams.push(curFilterParams);
        }
      });
    } else {
      currentFiltersDictionaryParams.forEach((curFilterParams) => {
        newFiltersDictionaryParams.push(curFilterParams);
      });
      newFiltersDictionaryParams.push(filterDictionaryForUpdate);
    }
  } else {
    newFiltersDictionaryParams.push(filterDictionaryForUpdate);
  }
  return newFiltersDictionaryParams;
};
