import React, { useRef, useState } from 'react';
import classes from './DwhRightSideBarInfoRow.module.css';
import { DwhRightSideBarEnum } from '../../DwhPage';
import { useAppSelector } from '../../../../hooks/hooks';
import { selectorIntegrationsDwhPage } from '../../../../store/dwhPageSlice';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconTrash } from '@consta/uikit/IconTrash';
import { getIntegrationByIdFromIntegrations } from '../../../../utils/DwhPageUtils/getIntegrationByIdFromIntegrations';
import { getTaskByIdFromIntegrations } from '../../../../utils/DwhPageUtils/getTaskByIdFromIntegrations';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { IconKebab } from '@consta/uikit/IconKebab';
import { ContextMenu } from '@consta/uikit/ContextMenu';
import ISPRightSideBarModalDelete from '../../../InfoSystemsPage/ISPRightSideBar/ISPRightSideBarModalDelete/ISPRightSideBarModalDelete';

type Item = {
  label: string;
  leftIcon: typeof IconEdit;
  accent?: 'alert';
};

const menuItemsIntegration: Item[] = [
  {
    label: 'Редактировать',
    leftIcon: IconEdit,
  },
  {
    label: 'Удалить',
    leftIcon: IconTrash,
    accent: 'alert',
  },
];

const menuItemsTask: Item[] = [
  {
    label: 'Редактировать',
    leftIcon: IconEdit,
  },
];

interface IDwhRightSideBarInfoRowProps {
  activeIntegrationOrTaskUuid: string | undefined;
  rightSideBarMode: DwhRightSideBarEnum | undefined;
  setRightSideBarMode?: (mode: DwhRightSideBarEnum | undefined) => void;
  closeSideBar?: () => void;
}

const DwhRightSideBarInfoRow: React.FC<IDwhRightSideBarInfoRowProps> = ({
  rightSideBarMode,
  setRightSideBarMode,
  closeSideBar,
  activeIntegrationOrTaskUuid,
}) => {
  const ref = useRef(null);
  // const dispatch = useAppDispatch();
  const integrations = useAppSelector(selectorIntegrationsDwhPage);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const integration = getIntegrationByIdFromIntegrations(integrations, activeIntegrationOrTaskUuid);
  const task = integration ? undefined : getTaskByIdFromIntegrations(integrations, activeIntegrationOrTaskUuid);

  const items = task ? menuItemsTask : menuItemsIntegration;

  const modalDeleteTitle = integration
    ? `Удалить интеграцию объект источник «${integration.source_object}»?`
    : undefined;

  const onClick = () => {
    setIsContextMenuOpen(!isContextMenuOpen);
  };

  const onItemClick = ({ item }: { item: Item }) => {
    if (item.label === 'Редактировать') {
      setRightSideBarMode && setRightSideBarMode(DwhRightSideBarEnum.EditIntegrationOrTask);
    } else if (item.label === 'Удалить') {
      setIsModalOpen(true);
    }
    setIsContextMenuOpen(false);
  };

  const onConfirmDelete = () => {
    if (integration?._uuid) {
      closeSideBar && closeSideBar();
      console.log('will delete integration');
      // dispatch(deleteInfoSystemThunk(infoSystem._uuid)).then(() => {
      //   const searchValueQuery = getQuerySearchValue('');
      //   const requestQuery = combineQuery([searchValueQuery, START_PAGINATION_QUERY]);
      //   dispatch(getInfoSystemsThunk(requestQuery));
      // });
    }
  };

  return (
    <div className={classes.container}>
      <Text className={classes.title}>
        {rightSideBarMode === DwhRightSideBarEnum.DisplayIntegrationOrTask
          ? task
            ? 'Шаг интеграции'
            : 'Интеграция'
          : rightSideBarMode === DwhRightSideBarEnum.CreateIntegration
          ? 'Создать Интеграцию'
          : rightSideBarMode === DwhRightSideBarEnum.EditIntegrationOrTask
          ? task
            ? 'Редактирование шаг интеграции'
            : 'Редактирование интеграции'
          : ''}
      </Text>
      {rightSideBarMode === DwhRightSideBarEnum.DisplayIntegrationOrTask && (
        <>
          <Button
            ref={ref}
            label="режим"
            view="ghost"
            size={'xs'}
            iconLeft={IconKebab}
            onlyIcon={true}
            onClick={onClick}
          />
          <ContextMenu
            isOpen={isContextMenuOpen}
            items={items}
            getItemStatus={(item) => item.accent}
            getItemLeftIcon={(item) => item.leftIcon}
            onItemClick={onItemClick}
            anchorRef={ref}
            direction="downStartLeft"
            style={{ zIndex: 999 }}
          />
        </>
      )}
      {modalDeleteTitle && (
        <ISPRightSideBarModalDelete
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          modalDeleteTitle={modalDeleteTitle}
          modalDeleteMessage={'Удаление интеграции приведет к потере данных по шагам интеграции'}
          onConfirmDelete={onConfirmDelete}
        />
      )}
    </div>
  );
};

export default DwhRightSideBarInfoRow;
