import React from 'react';
import classes from './DwhDisplayIntegrationOrTask.module.css';
import DwhRightSideBarInfoRow from '../DwhRightSideBarInfoRow/DwhRightSideBarInfoRow';
import { DwhRightSideBarEnum } from '../../DwhPage';
import { useAppSelector } from '../../../../hooks/hooks';
import { selectorIntegrationsDwhPage } from '../../../../store/dwhPageSlice';
import { getIntegrationByIdFromIntegrations } from '../../../../utils/DwhPageUtils/getIntegrationByIdFromIntegrations';
import { getTaskByIdFromIntegrations } from '../../../../utils/DwhPageUtils/getTaskByIdFromIntegrations';
import DwhDisplayIntegrationForm from './DwhDisplayIntegrationForm/DwhDisplayIntegrationForm';
import DwhDisplayTaskForm from './DwhDisplayTaskForm/DwhDisplayTaskForm';

interface IDwhDisplayIntegrationOrTaskProps {
  activeIntegrationOrTaskUuid: string | undefined;
  rightSideBarMode: DwhRightSideBarEnum | undefined;
  setRightSideBarMode?: (mode: DwhRightSideBarEnum | undefined) => void;
  closeSideBar?: () => void;
}

const DwhDisplayIntegrationOrTask: React.FC<IDwhDisplayIntegrationOrTaskProps> = ({
  activeIntegrationOrTaskUuid,
  setRightSideBarMode,
  rightSideBarMode,
  closeSideBar,
}) => {
  const integrations = useAppSelector(selectorIntegrationsDwhPage);
  const integration = getIntegrationByIdFromIntegrations(integrations, activeIntegrationOrTaskUuid);
  const task = integration ? undefined : getTaskByIdFromIntegrations(integrations, activeIntegrationOrTaskUuid);

  return (
    <div className={classes.container}>
      <DwhRightSideBarInfoRow
        activeIntegrationOrTaskUuid={activeIntegrationOrTaskUuid}
        rightSideBarMode={rightSideBarMode}
        setRightSideBarMode={setRightSideBarMode}
        closeSideBar={closeSideBar}
      />
      {task ? (
        <DwhDisplayTaskForm integrations={integrations} task={task} />
      ) : (
        <DwhDisplayIntegrationForm integration={integration} />
      )}
    </div>
  );
};

export default DwhDisplayIntegrationOrTask;
