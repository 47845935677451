import React from 'react';
import classes from './DwhDisplayIntegrationForm.module.css';
import { IntegrationType } from '../../../../../types/types';
import { Text } from '@consta/uikit/Text';

interface IDwhDisplayIntegrationFormProps {
  integration: IntegrationType | undefined;
}

const DwhDisplayIntegrationForm: React.FC<IDwhDisplayIntegrationFormProps> = ({ integration }) => {
  return (
    <div className={classes.container}>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Код'}</Text>
        <Text className={classes.sectionValue}>{integration?.integration_id ? integration.integration_id : '-'}</Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Интегрируемая система'}</Text>
        <Text className={classes.sectionValue}>{integration?.system?.name ? integration.system.name : '-'}</Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Объект источник'}</Text>
        <Text className={classes.sectionValue}>{integration?.source_object ? integration.source_object : '-'}</Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Получено из логов'}</Text>
        <Text className={classes.sectionValue}>{integration?.was_obtained_from_logs ? 'Да' : 'Нет'}</Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Описание'}</Text>
        <Text className={classes.sectionValue}>{integration?.description ? integration.description : '-'}</Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Разработчик'}</Text>
        <Text className={classes.sectionValue}>
          {integration?.developer?.full_name ? integration.developer?.full_name : '-'}
        </Text>
      </div>
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{'Ответственный'}</Text>
        <Text className={classes.sectionValue}>
          {integration?.responsible?.full_name ? integration.responsible?.full_name : '-'}
        </Text>
      </div>
    </div>
  );
};

export default DwhDisplayIntegrationForm;
