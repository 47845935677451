import React, { useEffect, useState } from 'react';

import classes from './TableColumnMultipleValuesFilter.module.css';
import TableColumnItemsFilter, { ItemsFilterFilterItemType } from '../TableColumnItemsFilter/TableColumnItemsFilter';
import { Button } from '@consta/uikit/Button';

type MetaResponseType = {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
};

type FilterParamsType = {
  filterName: string;
  filterValue: string | undefined;
};

type FilterDictionaryItemType = {
  uuid: string;
  name: string;
  slug?: string;
  isChecked?: boolean;
  id?: string;
  value?: string;
};

type FilterDictionaryType = {
  filterName: string;
  data?: FilterDictionaryItemType[];
  meta?: MetaResponseType;
  searchValue?: string;
};

type ObjectFilterItemsServerResponseType = {
  data: FilterDictionaryItemType[];
  meta?: MetaResponseType;
};

interface ITableColumnMultipleValuesFilterProps {
  filterName: string;
  applyFilter: (filterParams: FilterParamsType, filterDictionary?: FilterDictionaryType) => void;
  filterDictionary?: FilterDictionaryType | undefined;
  getObjectFilterItems: ({
    params,
    filterName,
  }: {
    params?: string;
    filterName: string;
  }) => Promise<ObjectFilterItemsServerResponseType>;
}

const transformToFilterItems = (data: FilterDictionaryItemType[] | undefined) => {
  if (!data) {
    return undefined;
  }
  return data.map((item) => ({ ...item, id: item.uuid, value: item.name ? item.name : '', isChecked: item.isChecked }));
};

const DEFAULT_PAGE_SIZE = 25;

const TableColumnMultipleValuesFilter: React.FC<ITableColumnMultipleValuesFilterProps> = ({
  filterName,
  applyFilter,
  filterDictionary,
  getObjectFilterItems,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSelectAll, setIsLoadingSelectAll] = useState(false);
  const [filterItems, setFilterItems] = useState<FilterDictionaryType | undefined>(
    filterDictionary ? { ...filterDictionary, data: transformToFilterItems(filterDictionary.data) } : undefined
  );

  useEffect(() => {
    if (!filterItems?.data) {
      setIsLoading(true);
      const params = `?page[size]=${DEFAULT_PAGE_SIZE}&page[number]=1`;
      getObjectFilterItems({ params, filterName })
        .then((res) => {
          setFilterItems({ filterName, meta: res.meta, data: transformToFilterItems(res.data) });
        })
        .catch((e) => {
          console.log(`Ошибка получения элементов для фильтра ${filterName}`, e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [filterItems?.data, filterName, getObjectFilterItems]);

  const onChangeSearchTermHandler = (value: string | null) => {
    const searchParams = value ? `filter[search]=${value}` : '';
    const paginationParams = `page[size]=${DEFAULT_PAGE_SIZE}&page[number]=1`;
    const params = searchParams ? `?${[searchParams, paginationParams].join('&')}` : `?${paginationParams}`;

    getObjectFilterItems({ params, filterName })
      .then((res) => {
        const searchFilteredData = transformToFilterItems(res.data);
        const checkedData = filterDictionary?.data?.filter((item) => item.isChecked);
        if (checkedData?.length) {
          const checkedSearchData = searchFilteredData?.map((item) => {
            if (checkedData.find((itm) => itm.uuid === item.uuid)?.isChecked) {
              return { ...item, isChecked: true };
            } else {
              return item;
            }
          });
          setFilterItems({
            filterName,
            meta: res.meta,
            data: checkedSearchData,
            searchValue: value ? value : undefined,
          });
        } else {
          setFilterItems({
            filterName,
            meta: res.meta,
            data: searchFilteredData,
            searchValue: value ? value : undefined,
          });
        }
      })
      .catch((e) => {
        console.log(`Ошибка получения элементов для фильтра ${filterName}`, e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChangeFilterItemHandler = (filterItem: ItemsFilterFilterItemType) => {
    if (filterItems?.data) {
      setFilterItems({
        ...filterItems,
        data: filterItems.data.map((item) =>
          item.id === filterItem.id ? { ...item, isChecked: item.isChecked ? undefined : true } : item
        ),
      });
    }
  };

  const applyFilterHandler = () => {
    const filterValue = filterItems?.data
      ?.filter((item) => item.isChecked)
      ?.map((item) => item.uuid)
      .join('|');
    applyFilter(
      { filterName, filterValue },
      { filterName, data: filterItems?.data, meta: filterItems?.meta, searchValue: filterItems?.searchValue }
    );
  };

  const resetFilterFnHandler = () => {
    applyFilter(
      { filterName, filterValue: undefined },
      { filterName, data: undefined, meta: undefined, searchValue: undefined }
    );
  };

  const onItemListScrollHandler = () => {
    if (
      filterItems?.meta?.current_page &&
      filterItems?.meta?.last_page &&
      filterItems?.meta?.current_page < filterItems?.meta?.last_page
    ) {
      setIsLoading(true);
      const searchParams = filterItems.searchValue ? `filter[search]=${filterItems.searchValue}` : '';
      const paginationParams = `page[size]=${DEFAULT_PAGE_SIZE}}&page[number]=${filterItems?.meta?.current_page + 1}`;
      const params = searchParams ? `?${[searchParams, paginationParams].join('&')}` : `?${paginationParams}`;

      getObjectFilterItems({ params, filterName })
        .then((res) => {
          const unionData: FilterDictionaryItemType[] = [];
          filterItems.data?.forEach((item) => {
            unionData.push(item);
          });
          transformToFilterItems(res.data)?.forEach((item) => {
            unionData.push(item);
          });

          const checkedData = filterDictionary?.data?.filter((item) => item.isChecked);
          if (checkedData?.length) {
            const checkedUnionData = unionData?.map((item) => {
              if (checkedData.find((itm) => itm.uuid === item.uuid)?.isChecked) {
                return { ...item, isChecked: true };
              } else {
                return item;
              }
            });
            setFilterItems({ filterName, meta: res.meta, data: checkedUnionData });
          } else {
            setFilterItems({ filterName, meta: res.meta, data: unionData });
          }
        })
        .catch((e) => {
          console.log(`Ошибка получения элементов для фильтра ${filterName}`, e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onSelectAllItemsHandler = () => {
    console.log('onSelectAllItems');
    const filterSearchValue = filterItems?.searchValue;
    if (filterItems) {
      if (filterItems?.meta?.total === filterItems?.data?.filter((item) => item.isChecked).length) {
        const deSelectedAllData = filterItems.data?.map((item) => ({ ...item, isChecked: false }));
        setFilterItems({
          filterName,
          meta: filterItems.meta,
          data: deSelectedAllData,
          searchValue: filterSearchValue,
        });
      } else {
        if (filterItems?.meta?.total === filterItems?.data?.length) {
          const selectedAllData = filterItems.data?.map((item) => ({ ...item, isChecked: true }));
          setFilterItems({
            filterName,
            meta: filterItems.meta,
            data: selectedAllData,
            searchValue: filterSearchValue,
          });
        } else {
          const searchParams = filterSearchValue ? `filter[search]=${filterSearchValue}` : '';
          const paginationParams = `page[size]=${filterItems?.meta?.total}&page[number]=1`;
          const params = searchParams ? `?${[searchParams, paginationParams].join('&')}` : `?${paginationParams}`;
          setIsLoadingSelectAll(true);
          getObjectFilterItems({ params, filterName })
            .then((res) => {
              const selectedAllData = transformToFilterItems(res.data)?.map((item) => ({ ...item, isChecked: true }));
              setFilterItems({
                filterName,
                meta: res.meta,
                data: selectedAllData,
                searchValue: filterSearchValue,
              });
            })
            .catch((e) => {
              console.log(`Ошибка получения элементов для фильтра ${filterName}`, e);
            })
            .finally(() => {
              setIsLoadingSelectAll(false);
            });
        }
      }
    }
  };

  return (
    <div className={classes.filterContainer}>
      <div style={{ width: '100%' }}>
        <TableColumnItemsFilter
          isLoading={isLoading}
          isLoadingSelectAll={isLoadingSelectAll}
          itemsTotal={filterItems?.meta?.total}
          itemsPerPage={filterItems?.meta?.per_page}
          selectedItemsNumber={filterItems?.data?.filter((item) => item.isChecked).length}
          items={filterItems?.data ? (filterItems.data as ItemsFilterFilterItemType[]) : []}
          searchTermValue={filterDictionary?.searchValue ? filterDictionary.searchValue : null}
          onChangeFilterItem={onChangeFilterItemHandler}
          onChangeSearchTerm={onChangeSearchTermHandler}
          onItemListScroll={onItemListScrollHandler}
          onSelectAllItems={onSelectAllItemsHandler}
          resetFilterFn={resetFilterFnHandler}
        />
        <div className={classes.buttonsWrapper}>
          <Button label="Применить" view="primary" width="full" onClick={applyFilterHandler} />
        </div>
      </div>
    </div>
  );
};

export default TableColumnMultipleValuesFilter;
