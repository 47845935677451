import { IntegrationsServerResponseType } from '../../api/serverResponse';

export const getIntegrationByIdFromIntegrations = (
  integrations: IntegrationsServerResponseType | undefined,
  activeIntegrationOrTaskUuid: string | undefined
) => {
  if (!integrations || !integrations.data.length || !activeIntegrationOrTaskUuid) {
    return undefined;
  }
  return integrations?.data.find((integration) => integration._uuid === activeIntegrationOrTaskUuid);
};
