import { instanceAxios } from './instanceAxios';
import { IntegrationsServerResponseType } from './serverResponse';
import { IntegrationType } from '../types/types';

export type CreateNewIntegrationParamsType = {
  description: string;
  uuid_system: string;
  source_object: string;
  uuid_user_responsible: string;
  uuid_user_developer: string;
};

export type UpdateIntegrationParamsType = {
  integrationUuid: string;
  description: string;
  uuid_system: string;
  source_object: string;
  uuid_user_responsible: string;
  uuid_user_developer: string;
};

export type UpdateIntegrationTaskParamsType = {
  taskUuid: string;
  integrationUuid: string;
  source_conn_str: string;
  target_conn_str: string;
};

export let abortControllerDwhPage = new AbortController();

export const dwhPageApi = {
  async getIntegrations(requestQuery: string) {
    return instanceAxios
      .get<IntegrationsServerResponseType>(`/dwh-integrations${requestQuery ? requestQuery : ''}`)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        if (e.name === 'CanceledError') {
          abortControllerDwhPage = new AbortController();
          return {
            data: [],
            meta: { from: 0, to: 0, total: 0, per_page: 0, current_page: 0, last_page: 0 },
          } as IntegrationsServerResponseType;
        } else {
          throw new Error(e.message);
        }
      });
  },

  async createNewIntegration(params: CreateNewIntegrationParamsType) {
    const response = await instanceAxios.post<{ data: IntegrationType }>(`/dwh-integrations`, params);
    return response.data.data;
  },

  async updateIntegration(params: UpdateIntegrationParamsType) {
    const response = await instanceAxios.put<{ data: IntegrationType }>(
      `/dwh-integrations/${params.integrationUuid}`,
      params
    );
    return response.data.data;
  },

  async updateIntegrationTask(params: UpdateIntegrationTaskParamsType) {
    const response = await instanceAxios.put<{ data: IntegrationType }>(
      `/dwh-integrations/${params.integrationUuid}/tasks/${params.taskUuid}`,
      params
    );
    return response.data.data;
  },
};
