import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import appReducer from './appSlice';
import metaReducer from './metaSlice';
import catalogReducer from './catalogSlice';
import infoSystemsReducer from './infoSystemsSlice';
import connectionsReducer from './connectionsSlice';
import createNewConnectionReducer from './createNewConnectionSlice';
import editConnectionReducer from './editConnectionSlice';
import launchHistoryReducer from './launchHistorySlice';
import dwhPageReducer from './dwhPageSlice';

import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import { PERSIST_STORAGE_KEY } from '../utils/constants';

const persistConfig = {
  key: PERSIST_STORAGE_KEY,
  storage: sessionStorage,
};

const reducers = combineReducers({
  app: appReducer,
  meta: metaReducer,
  catalog: catalogReducer,
  infoSystems: infoSystemsReducer,
  connections: connectionsReducer,
  createNewConnection: createNewConnectionReducer,
  editConnection: editConnectionReducer,
  launchHistory: launchHistoryReducer,
  dwhPage: dwhPageReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
