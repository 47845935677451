import { ReactNode } from 'react';
import { ColumnGenericType } from '../../../../../../types/types';
import { createTableColumn } from '../../../../../../shared-front/Table/utils/createTableColumn';
import { TableRdbDataType } from './tableRdbColumns';

export type TableCdbDataType = {
  system?: string | null;
  name?: string | null;
  hierarchy?: ReactNode | null;
  unified_object?: string | null;
  connector_type?: string | null;
  _alter_ts?: string | null;
  description_name?: string | null;
  level_1?: string | null;
  level_2?: string | null;
  object_attr?: string | null;
  attr_type?: string | null;
  object_kind?: string | null;
  description_value?: string | null;
  comment?: string | null;
  organization?: string | null;
  ts_alter?: string | null;
  db?: string | null;
  schema?: string | null;
  object?: string | null;
};

export const TABLE_CDB_COLUMNS: ColumnGenericType[] = [
  createTableColumn<TableCdbDataType>({
    accessorKey: 'system',
    title: 'Интегрируемая Система',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<TableCdbDataType>({
    accessorKey: 'name',
    title: 'Наименование',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<TableCdbDataType>({
    accessorKey: 'hierarchy',
    title: 'Иерархия',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<TableCdbDataType>({
    accessorKey: 'unified_object',
    title: 'Тип',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<TableCdbDataType>({
    accessorKey: 'connector_type',
    title: 'Тип источника',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<TableCdbDataType>({
    accessorKey: '_alter_ts',
    title: 'Изменено',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<TableCdbDataType>({
    accessorKey: 'description_name',
    title: 'Краткое техническое описание',
    textLinesNumber: 2,
    isShow: true,
    size: 300,
  }),
  createTableColumn<TableRdbDataType>({
    accessorKey: 'full_name',
    title: 'Полное наименование',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<TableCdbDataType>({
    accessorKey: 'db',
    title: 'База данных',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<TableCdbDataType>({
    accessorKey: 'object',
    title: 'Объект данных',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<TableCdbDataType>({
    accessorKey: 'attr',
    title: 'Атрибут',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<TableCdbDataType>({
    accessorKey: 'attr_type',
    title: 'Тип данных атрибута',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<TableCdbDataType>({
    accessorKey: 'object_kind',
    title: 'Тип объекта данных БД',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<TableCdbDataType>({
    accessorKey: 'description_value',
    title: 'Расширенное техническое описание',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<TableCdbDataType>({
    accessorKey: 'comment',
    title: 'Описание из системы-источник',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<TableCdbDataType>({
    accessorKey: 'organization',
    title: 'Организация',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
  createTableColumn<TableCdbDataType>({
    accessorKey: 'ts_alter',
    title: 'Изменено в системе-источнике',
    textLinesNumber: 2,
    isShow: false,
    size: 300,
  }),
];
