import React from 'react';
import classes from './CustomTextFieldWithCopyBtn.module.css';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';
import { IconCopy } from '@consta/uikit/__internal__/src/icons/IconCopy/IconCopy';
import { Button } from '@consta/uikit/Button';

interface ICustomTextFieldWithCopyBtnProps {
  label: string;
  value: string | null;
  onChange: (val: string | null) => void;
  error: string | undefined;
  onClickCopy: () => void;
}

const CustomTextFieldWithCopyBtn: React.FC<ICustomTextFieldWithCopyBtnProps> = ({
  label,
  value,
  onChange,
  error,
  onClickCopy,
}) => {
  const onChangeHandler = ({ value }: { value: string | null }) => {
    onChange(value);
  };

  const onClickCopyToBuffer = async () => {
    await navigator.clipboard.writeText(value ?? '');
    onClickCopy();
  };

  return (
    <div className={classes.container}>
      <div className={classes.combineText}>
        <Text className={classes.sectionTitle}>{label}</Text>
        <Text className={classes.requiredSign}>{'*'}</Text>
      </div>
      <div className={classes.textFieldContainer}>
        <div className={classes.row}>
          <TextField
            onChange={onChangeHandler}
            value={value}
            type="text"
            placeholder={''}
            size={'m'}
            status={error ? 'alert' : undefined}
            style={{ width: '100%' }}
          />
          <Button size="m" view="clear" iconLeft={IconCopy} onlyIcon onClick={onClickCopyToBuffer} />
        </div>
        <Text className={classes.sectionAlert}>{error}</Text>
      </div>
    </div>
  );
};

export default CustomTextFieldWithCopyBtn;
